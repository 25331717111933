import TextField from '@mui/material/TextField';

export default function TextArea1({
    maxLength = 255,
    className = "w-100",
    disabled = false,
    style={ backgroundColor: "white"},
    size="small",
    label,
    rows="5",
    ...rest
}) {
    return (<TextField
        style={style}
        size={size}

        inputProps={{ maxLength }}
        className={className}

        disabled={disabled}
        label={label}
        multiline
        rows={rows}

        {...rest}
    />)
}