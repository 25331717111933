import BoostrapFormControl from "../comps/EntitasEditor/BoostrapFormControl"
import React,{useState} from 'react'
import ReactDOM from 'react-dom/client'

export default function B () {
        const [entitas,setEntitas]=useState({id:1,nev:"alma"})
        console.log(entitas);
        return (
            <>
                Funkcionális komponens
                <BoostrapFormControl 
                    key={entitas.id}
                    prop="nev"
                    entitas={entitas}
                    label="név"
                    defaultValue="sajt"
                    onChangeHandler={({entitas,prop,event,value})=>
                        setEntitas(
                            prevstate => ({
                                 ...prevstate, nev:  event.target.value,
                            })
                        )
                    }
                />

                <button 
                    onClick={e=>{
                        console.log(entitas)
                    }}

                >gomb1</button>

                <button 
                    onClick={e=>{
                        setEntitas({id:2,nev:null})
                    }}

                >gomb2</button>


            </>
        );
}