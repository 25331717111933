import { useState, useMemo, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import "leaflet/dist/leaflet.css"
import "leaflet-draw/dist/leaflet.draw.css"
import L from "leaflet";
import "leaflet-draw";
import "leaflet-control-geocoder/dist/Control.Geocoder.css"
import "leaflet-control-geocoder"

// Event-ek: leaflet draw
// https://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#l-draw-event

const MapWithDraw = ({
    onCenterOrZoomChange,
    centerAndZoomValue,
    onPolygonChange,
    polygonValue,    
}) => {
    const lasVegasCoord=[36.125,-115.175]
    const defaultZoom=10
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const drawnItemsRef = useRef(null);
    const polygonRef =useRef(null);
    const isEditActive = useRef(false);
    const [boundingBox,setBoundingBox]=useState(true)

    useEffect(() => {
        const map = L.map(mapContainerRef.current).setView(
            centerAndZoomValue?.center!=null?centerAndZoomValue?.center:lasVegasCoord, 
            centerAndZoomValue?.zoom!=null?centerAndZoomValue?.zoom:defaultZoom, 
        );
        mapRef.current=map

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors'
        }).addTo(map);

        L.Control.geocoder().addTo(map);

        const drawnItems = new L.FeatureGroup();
        drawnItemsRef.current=drawnItems
        map.addLayer(drawnItems);


        L.DrawToolbar.include({
            getModeHandlers: function (map) {
                return [{
                    enabled: this.options.polygon,
                    handler: new L.Draw.Polygon(map, this.options.polygon),
                    title: L.drawLocal.draw.toolbar.buttons.polygon
                }
                ];
            }
        });

        const drawControl = new L.Control.Draw({
            edit: {
                featureGroup: drawnItems
            }
        });

        map.addControl(drawControl);

        map.on('draw:created', function (e) {
            var type = e.layerType;
            var layer = e.layer;

            if (type === 'polygon') {
                if(polygonRef.current)
                    drawnItemsRef.current.removeLayer(polygonRef.current)
                polygonRef.current = layer;
                onPolygonChange(polygonRef.current.getLatLngs())
                setBoundingBox(polygonRef?.current?.getLatLngs()?.[0]?.length>1)   
            }
        });

        map.on("draw:deleted",(e)=>{
            onPolygonChange([])
            setBoundingBox(false)            
        })       

        map.on('draw:editstart', function (e) {
            isEditActive.current=true
        });     
        
        map.on('draw:editstop', function (e) {
            isEditActive.current=false
        });  

        map.on('draw:edited', function (e) {
            onPolygonChange(polygonRef.current.getLatLngs())
        });  

        map.on('draw:edited', function (e) {
            onPolygonChange(polygonRef.current.getLatLngs())
        });    



        map.on("zoomend", (e)=> {
            if(!isEditActive.current)
                onCenterOrZoomChange(getCenterAndZoom())
        });

        map.on("dragend", (e)=> {
            if(!isEditActive.current)
                onCenterOrZoomChange(getCenterAndZoom())
        });     




        return () => {
            map.remove();
        };
    }, [mapContainerRef.current]);

    // A hook-ok a definiálási sorrendjükben futnak le
    useEffect(()=>{
        if(
            mapContainerRef.current!=null && 
            mapRef.current!=null && 
            mapRef.current.setView!=null  && 
            centerAndZoomValue!=null 
        ) {
            mapRef.current.setView(
                centerAndZoomValue?.center!=null?centerAndZoomValue?.center:lasVegasCoord, 
                centerAndZoomValue?.zoom!=null?centerAndZoomValue?.zoom:defaultZoom, 
            );
        }
    },[centerAndZoomValue])


    const getCenterAndZoom=()=> {
        return({
            center: mapRef.current.getCenter(),
            zoom: mapRef.current.getZoom()
        })
    }    

    // A hook-ok a definiálási sorrendjükben futnak le
    useEffect(()=>{
        if(
            mapContainerRef.current!=null && 
            mapRef.current!=null && 
            mapRef.current.setView!=null  && 
            drawnItemsRef.current!=null &&
            polygonValue!=null 
        ) {
            if(polygonRef.current)
                drawnItemsRef.current.removeLayer(polygonRef.current)
            polygonRef.current = L.polygon(polygonValue)
            drawnItemsRef.current.addLayer(polygonRef.current)
            setBoundingBox(polygonRef?.current?.getLatLngs()?.[0]?.length>1)   
        } else 
            setBoundingBox(false)
    },[polygonValue])   
    

   
    

    const getCoords = () => {
        if (marker != null && polygon != null) {
            const coord = convertToLatLng(marker.getLatLng())
            const coords = convertToLatLngArray(polygon.getLatLngs())
        }
        if (map) {
            const center = map.getCenter()
            const zoom = map.getZoom()
        }

    }

    /*
    ██████  ███████ ███████  ██████   ██████  ██       █████  ██       ██████
    ██   ██ ██      ██      ██    ██ ██       ██      ██   ██ ██      ██    ██
    ██████  █████   █████   ██    ██ ██   ███ ██      ███████ ██      ██    ██
    ██   ██ ██      ██      ██    ██ ██    ██ ██      ██   ██ ██      ██    ██
    ██████  ███████ ██       ██████   ██████  ███████ ██   ██ ███████  ██████
    */



    const showBefoglalo=()=> {
        try {
            if(!polygonRef.current) {
                alert("Map without polygon!")
                return
            }
            const bounds=polygonRef.current.getBounds();
            mapRef.current.fitBounds(bounds);
            if(!isEditActive.current)
                onCenterOrZoomChange(
                    {
                        center: bounds.getCenter(),
                        zoom: mapRef.current.getZoom()
                    })
        } catch(e) {
            console.log(e)
        }
    }

    
    /*
    ██████  ███████ ████████ ██    ██ ██████  ███    ██
    ██   ██ ██         ██    ██    ██ ██   ██ ████   ██
    ██████  █████      ██    ██    ██ ██████  ██ ██  ██
    ██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██
    ██   ██ ███████    ██     ██████  ██   ██ ██   ████


    */

    return (
        <>
            
            
            <div ref={mapContainerRef} style={{ zIndex:1,width: "100%", height: '400px', border:"solid 1px gray" }} />
            {
                boundingBox &&
                <><a href="#" onClick={(e)=>{e.preventDefault();showBefoglalo()}}>Bounding-box</a>&nbsp;|  &nbsp;</>
            }
            <a href="https://www.google.com/maps" target="_blank">Google Maps...</a> (You can type lat.,long.values into the search input)
        </>


    );

};

export default MapWithDraw

function convertToLatLngArray(coords) {
    return coords.map(coord => convertToLatLng(coord));
}

function convertToLatLng(coord) {
    return [coord.lat, coord.lng];
}

function inside(point, vs) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

    var x = point[0],
        y = point[1];

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i][0],
            yi = vs[i][1];
        var xj = vs[j][0],
            yj = vs[j][1];

        var intersect = ((yi > y) != (yj > y)) &&
            (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
};




