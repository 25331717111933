import React from 'react'
import ReactDOM from 'react-dom/client'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import ClientTeammateController from "./ClientTeammateController"
import ClientPositionController from "./ClientPositionController"
import Plusz from "../assets/plus-svgrepo-com.svg"
import { nanoid } from 'nanoid'
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Popover from "../comps/Popover"
import MapWithDraw from "../map/MapWithDraw"
import TimezoneSelect from 'react-timezone-select'
import BoostrapFormControl from "../comps/EntitasEditor/BoostrapFormControl"


export default class ClientAdatlap extends React.Component {
    constructor(props) {
        super(props);
        this.id = props.id

        this.firstWorkDayControllerOptions= [
            { value: "", label: "Please choose a day" },
            ...[
                "MONDAY",
                "TUESDAY",
                "WEDNESDAY",
                "THURSDAY",
                "FRIDAY",
                "SATURDAY",
                "SUNDAY"
            ].map(e=>{return{value: e, label: _.capitalize(e)}})
          ]

        this.onSaveOrUpdateListener = props.onSaveOrUpdate
        this.entitas = this.props.entitas
        this.state = {
            entitas: {
                id: null
            },
            clientTeamMates:[],
            positions:[],
            firstWorkDayController:this.firstWorkDayControllerOptions[0],
        };
        this.formRef = React.createRef()
    }

   

    /*
    ██       ██████   █████  ██████  ███████ ███    ██ ████████ ██ ████████  █████  ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ████   ██    ██    ██    ██    ██   ██ ██
    ██      ██    ██ ███████ ██   ██ █████   ██ ██  ██    ██    ██    ██    ███████ ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ██  ██ ██    ██    ██    ██    ██   ██      ██
    ███████  ██████  ██   ██ ██████  ███████ ██   ████    ██    ██    ██    ██   ██ ███████
    */

    loadEntitas = async (id) => {
        const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/client/" + id+"/k")
        const dto = resp.data
        this.setState({ 
            entitas: dto.client,
            firstWorkDayController: !dto.client.firstWorkDay?
                this.firstWorkDayControllerOptions[0]:
                {value:dto.client.firstWorkDay,label:_.capitalize(dto.client.firstWorkDay)},
            clientTeamMates:dto.teamMates,
            positions:dto.positions
        })
    }

    componentDidMount = () => {
        if (!this.entitas && this.id) this.loadEntitas(this.id)
    }

    saveOrUpdate = async () => {
        const client=this.state.entitas
        client.firstWorkDay=this.state.firstWorkDayController.value || null      
        const dtoKeres={
            client:client,
            teamMates:this.state.clientTeamMates,
            positions:this.state.positions
        }
        console.log("Kuldendo DTO");
        console.log(dtoKeres);
        const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/client/k", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify(dtoKeres)
        })
        // TODO 
        if(resp.status!=200) 
            alert("Error, please watch the console.")
        else
            if(false)alert("Successful save")
        const dtoValasz = resp.data
        this.setState(
            { 
                entitas: dtoValasz.client,
                clientTeamMates: dtoValasz.teamMates,
                positions:dtoValasz.positions
            })
        if (this.onSaveOrUpdateListener) this.onSaveOrUpdateListener(dtoValasz.client)
    }

    onPluszPosition=() => {
        this.setState(elozo=>({positions:[...elozo.positions,
            {
                "name": "",
                "billRate": "",
                "overtime": "",
                "tmpid":  nanoid()
            }
        ]}))
    }


    onPluszTeammate=() => {
        this.setState(elozo=>({clientTeamMates:[...elozo.clientTeamMates,
            {
                "title": "",
                "name": "",
                "phone": "",
                "email": "",
                "invoiceHandler": false,
                "timecardHandler": false,
                "tmpid":  nanoid()
            }
        ]}))
    }

    onMinusz=(tombIndex) => {
        const modositott=[...this.state.clientTeamMates]
        modositott.splice(tombIndex, 1)    
        this.setState({clientTeamMates:modositott})
    } 

    onMinuszPosition=(tombIndex) => {
        const modositott=JSON.parse(JSON.stringify(this.state.positions))
        modositott.splice(tombIndex, 1)
        this.setState({positions:modositott})
    } 
    
    
    onTeammateValtozasFigyelo=(e) => {
        const teammates=[...this.state.clientTeamMates]
        teammates[e.index][e.tulajdonsag]=e.ujertek
        this.setState({clientTeamMates:teammates})
    }

    onTeammateEntitasValtozasFigyelo=(v) => {
        const teammates=[...this.state.clientTeamMates]
        teammates[v.index]=v.entitas
        this.setState({clientTeamMates:teammates})
    }    

    onPositionValtozasFigyelo=(e) => {
        const positions=[...this.state.positions]
        positions[e.index][e.tulajdonsag]=e.ujertek
        this.setState({positions:positions})
    }    

    render() {


        
        const teamMatesControllers=!this.state.clientTeamMates?null:this.state.clientTeamMates.map((teammate,i)=> {
            return <ClientTeammateController
                teammate={teammate} 
                key={[i,teammate.id+teammate.version || teammate.tmpid].join("-")}   index={i}
                onMinuszFigyelo={this.onMinusz}
                onValtozasFigyelo={this.onTeammateValtozasFigyelo}
                onTeammateEntitasValtozasFigyelo={this.onTeammateEntitasValtozasFigyelo}
            />
        })

        const positionsControllers=!this.state.positions?null:this.state.positions.map((p,i)=> {
            return <ClientPositionController
                position={p} 
                key={[i,p.id || p.tmpid].join("-")} index={i}
                onMinuszFigyelo={this.onMinuszPosition}
                onValtozasFigyelo={this.onPositionValtozasFigyelo}
            />
        })               

        const createFormControl=({
            maxLength=255,
            prop,
            label,
            ...rest
        })=>{
            return(
                <Form.Control 
                    type="text" 
                    placeholder={label}
                    maxLength={255}  
                    name={prop}               
                    value={this.state.entitas?.[prop] || ""}
                    onChange={event => {
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [prop]: event.target.value },
                                saved: false
                            })
                        );
                    }}
                    {...rest}
                />
            )
        }


        Intl.DateTimeFormat().resolvedOptions().timeZone

        return (
            <div style={{ border: "solid 1px lightgray", padding: "20px", borderRadius: "20px" }}>
                <h2>
                    {!this.state.entitas?.id &&
                        "New client"
                    }
                    {this.state.entitas?.id &&
                        <Popover popovertext={this.state.entitas?.id}>
                            Client
                        </Popover>
                    }
                </h2>
                <hr />
                <form ref={this.formRef}>
                    <Form.Group className="mb-3" controlId="companyName">
                        <Form.Label>Company name</Form.Label>
                        {createFormControl({prop:"companyName",label:"company name"})}


                            
                    </Form.Group>



                    <Row className="mb-0 mb-md-3">
                        <Col>
                            <Form.Group className="col-12 col-md-6 mb-3 mb-md-0" controlId="address" style={{ display: "inline-block" }}>
                                <Form.Label>Address</Form.Label>
                                {createFormControl({prop:"address",label:"address"})}

                            </Form.Group>
                            <Form.Group className="col-12 col-md-6 ps-md-2 mb-3 mb-md-0" controlId="city" style={{ display: "inline-block" }}>
                                <Form.Label>City</Form.Label>
                                {createFormControl({prop:"city",label:"city"})}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/*
                    ███████ ████████  █████  ████████ ███████     ███████ ██ ██████   ██████  ██████  ██████  ███████
                    ██         ██    ██   ██    ██    ██             ███  ██ ██   ██ ██      ██    ██ ██   ██ ██
                    ███████    ██    ███████    ██    █████         ███   ██ ██████  ██      ██    ██ ██   ██ █████
                         ██    ██    ██   ██    ██    ██           ███    ██ ██      ██      ██    ██ ██   ██ ██
                    ███████    ██    ██   ██    ██    ███████     ███████ ██ ██       ██████  ██████  ██████  ███████


                    ███████ ██ ██████  ███████ ████████ ██     ██  ██████  ██████  ██   ██ ██████   █████  ██    ██
                    ██      ██ ██   ██ ██         ██    ██     ██ ██    ██ ██   ██ ██  ██  ██   ██ ██   ██  ██  ██
                    █████   ██ ██████  ███████    ██    ██  █  ██ ██    ██ ██████  █████   ██   ██ ███████   ████
                    ██      ██ ██   ██      ██    ██    ██ ███ ██ ██    ██ ██   ██ ██  ██  ██   ██ ██   ██    ██
                    ██      ██ ██   ██ ███████    ██     ███ ███   ██████  ██   ██ ██   ██ ██████  ██   ██    ██
                    */}
                    <Row>
                        <Col>                  
                            <Form.Group className="col-12 col-md-4 d-inline-block pe-0 pe-md-2 mb-3 mb-md-0" controlId="state">
                                <Form.Label>State</Form.Label>
                                    {createFormControl({prop:"state",label:"state"})}
                            </Form.Group>
                            <Form.Group className="col-12 col-md-4 d-inline-block pe-0 pe-md-2 mb-3 mb-md-0" controlId="zipCode">
                                <Form.Label>Zip code</Form.Label>
                                    {createFormControl({prop:"zipCode",label:"zip code"})}
                            </Form.Group>                    
                            <Form.Group className="col-12 col-md-4 d-inline-block" controlId="firstWorkDay">
                                <Form.Label>First workday</Form.Label>
                                <Select 
                                    menuPortalTarget={document.body} 
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={this.firstWorkDayControllerOptions} 
                                    name="firstWorkDay"    
                                    value={this.state.firstWorkDayController}
                                    onChange={e=>{this.setState({firstWorkDayController:e})}}

                                />
                            </Form.Group>
                        </Col>
                    </Row>   


                    {/*
                    ███    ███  █████  ██████
                    ████  ████ ██   ██ ██   ██
                    ██ ████ ██ ███████ ██████
                    ██  ██  ██ ██   ██ ██
                    ██      ██ ██   ██ ██
                     */}


                    <div className="my-4">
                        <MapWithDraw 
                            key="1" 
                            centerAndZoomValue={
                                this.state.entitas?.mapCenterAndZoom!=null?
                                    JSON.parse(this.state.entitas.mapCenterAndZoom):
                                    null
                            }
                            onCenterOrZoomChange={(v)=>{
                                this.setState(
                                    prevstate => ({
                                        entitas: { 
                                            ...prevstate.entitas, 
                                            mapCenterAndZoom: JSON.stringify(v)},
                                        saved: false
                                    })
                                );
                            }}

                            polygonValue={
                                this.state.entitas?.mapPolygon!=null?
                                    JSON.parse(this.state.entitas.mapPolygon):
                                    null
                            }

                            onPolygonChange={
                                (v)=>{
                                this.setState(
                                    prevstate => ({
                                        entitas: { 
                                            ...prevstate.entitas, 
                                            mapPolygon: JSON.stringify(v)},
                                        saved: false
                                    })
                                );                                
                            }}
                            />
                    </div>

                    {/*
                    ████████ ██ ███    ███ ███████     ███████  ██████  ███    ██ ███████
                       ██    ██ ████  ████ ██             ███  ██    ██ ████   ██ ██
                       ██    ██ ██ ████ ██ █████         ███   ██    ██ ██ ██  ██ █████
                       ██    ██ ██  ██  ██ ██           ███    ██    ██ ██  ██ ██ ██
                       ██    ██ ██      ██ ███████     ███████  ██████  ██   ████ ███████
                    */}

                    <Row>
                        <Col>                  
                            <Form.Group className="col-12 pe-0 pe-md-2 mb-3 mb-md-0" controlId="state">
                                <Form.Label>Time zone</Form.Label> 
                                <TimezoneSelect styles={{width:"100%"}}
                                    value={this.state.entitas?.timeZone || ""}
                                    onChange={timezone  => {
                                        this.setState(
                                            prevstate => ({
                                                entitas: { ...prevstate.entitas, timeZone: timezone.value },
                                                saved: false
                                            })
                                        );
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>  

                    {
                        this.state.entitas?.timeZone && 
                        <p className="my-3">Local date in client timezone: {this.state.entitas.currentLocalDateInTimeZone}</p>
                    } 

                    {/*
                    ██      ██    ██ ███    ██  ██████ ██   ██ ██████  ██████  ███████  █████  ██   ██
                    ██      ██    ██ ████   ██ ██      ██   ██ ██   ██ ██   ██ ██      ██   ██ ██  ██
                    ██      ██    ██ ██ ██  ██ ██      ███████ ██████  ██████  █████   ███████ █████
                    ██      ██    ██ ██  ██ ██ ██      ██   ██ ██   ██ ██   ██ ██      ██   ██ ██  ██
                    ███████  ██████  ██   ████  ██████ ██   ██ ██████  ██   ██ ███████ ██   ██ ██   ██
                    */}

                    <Row className="my-3">
                        <Col className="col-12 mb-3 mb-md-0 col-md-auto d-flex align-items-center ">
                                <Form.Label className="m-0">Deductible lunch break duration (mins):</Form.Label>
                         </Col>                    
                        <Col className="col-12 mb-3 mb-md-0 col-md-auto d-flex align-items-center flex-fill">
                            <BoostrapFormControl 
                                key={this.state.entitas?.id}
                                prop="deductibleLunchBreakDuration"
                                entitas={this.state.entitas}
                                label="deductible lunch break duration"
                                defaultValue={0}
                                onChangeHandler={({entitas,prop,event,value})=>
                                    this.setState(
                                            prevstate => ({
                                                entitas: { ...prevstate.entitas, deductibleLunchBreakDuration: value },
                                                saved: false
                                            })
                                        )
                                }
                            />  
                        </Col>
                    </Row>


                </form>



                <div style={{border:"solid 1px lightgray",borderRadius:"10px"}} className="p-3 mt-4">
                    <p style={{fontWeight:"bold"}}>
                        {this.state.positions && "Positions"}
                    </p>
                    {this.state.positions && positionsControllers}
                    <p className="mt-3 p-1" style={{border:"solid 1px lightgray",borderRadius:"10px",background:"rgba(0,0,0,0.05"}}>
                        <a onClick={this.onPluszPosition}>
                            <img src={Plusz} style={{width:"25px"}}/>
                        </a>
                    </p>
                </div>

                <div style={{border:"solid 1px lightgray",borderRadius:"10px"}} className="p-3 mt-4">
                    <p style={{fontWeight:"bold"}}>
                        {this.state.clientTeamMates && "Managers"}
                    </p>
                    {this.state.clientTeamMates && teamMatesControllers}
                    <p className="mt-3 p-1" style={{border:"solid 1px lightgray",borderRadius:"10px",background:"rgba(0,0,0,0.05"}}>
                        <a onClick={this.onPluszTeammate}>
                            <img src={Plusz} style={{width:"25px"}}/>
                        </a>
                    </p>
                </div>                
                

                <Button
                    onClick={this.saveOrUpdate}
                    className="m-2 ms-0 mt-5"
                    variant="primary"
                >
                    {this.state.entitas?.id ? "Save item" : "Create new item"}
                </Button>
            </div>
        );
    }
}