import React from 'react'
import ReactDOM from 'react-dom/client'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import _ from 'lodash';
import axios from 'axios';
import TextField1 from '../comps/datasheet/mui/TextField1';
import TextFieldWithUnit1 from '../comps/datasheet/mui/TextFieldWithUnit1';
import TextArea1 from '../comps/datasheet/mui/TextArea1';
import dayjs, { Dayjs } from 'dayjs';
import DatePicker1 from '../comps/datasheet/mui/DatePicker1';
import EmployeeSelection from '../comps/datasheet/mui/EmployeeSelection';
import ClientSelection from '../comps/datasheet/mui/ClientSelection';
import ClientPositionSelection from '../comps/datasheet/mui/ClientPositionSelection';
import Popover from '../comps/Popover';
import * as UtilsVSM from "../UtilsVSM"
import Alert from '@mui/material/Alert';

export default class EmploymentRelationshipAdatlap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.id = props.id
        // Ha mar eleve egy employee-hoz kotodik az adatlap, tehat masikat nem lehet valasztani
        // Ez azert van mert a munkaviszon tabla es adatlap megjelenik az employee adatlapjan
        this.employeeID=props.employeeID 
        this.additionalButton=props.button
        this.onSaveOrUpdateListener = props.onSaveOrUpdate
        this.entitas = this.props.entitas
        this.state = {
            entitas: {
                id: null
            },
            saved: this.entitas?.id ? true : false
        };        
    }


    /*
    ██       ██████   █████  ██████  ███████ ███    ██ ████████ ██ ████████  █████  ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ████   ██    ██    ██    ██    ██   ██ ██
    ██      ██    ██ ███████ ██   ██ █████   ██ ██  ██    ██    ██    ██    ███████ ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ██  ██ ██    ██    ██    ██    ██   ██      ██
    ███████  ██████  ██   ██ ██████  ███████ ██   ████    ██    ██    ██    ██   ██ ███████
    */

    loadEntitas = async (id) => {
        const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employment-relationship/" + id)
        const eResp = resp.data
        this.setState({
            entitas: eResp,
            saved: eResp.id?true:false
        })
    }

    /*
     ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████ ██████  ██ ██████  ███    ███  ██████  ██    ██ ███    ██ ████████
    ██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██    ██   ██ ██ ██   ██ ████  ████ ██    ██ ██    ██ ████   ██    ██
    ██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██    ██   ██ ██ ██   ██ ██ ████ ██ ██    ██ ██    ██ ██ ██  ██    ██
    ██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██    ██   ██ ██ ██   ██ ██  ██  ██ ██    ██ ██    ██ ██  ██ ██    ██
     ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██    ██████  ██ ██████  ██      ██  ██████   ██████  ██   ████    ██
    */

    componentDidMount = () => {
        if (!this.entitas && this.id) this.loadEntitas(this.id)
        if (!this.entitas && !this.id && this.employeeID) {
            (async (id) => {
                const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employee/" + id)
                const data = resp.data
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, employee: data },
                    })
                );                
            }) (this.employeeID)
        } 
    }

    /*
    ███████  █████  ██    ██ ███████  ██████  ██████  ██    ██ ██████  ██████   █████  ████████ ███████
    ██      ██   ██ ██    ██ ██      ██    ██ ██   ██ ██    ██ ██   ██ ██   ██ ██   ██    ██    ██
    ███████ ███████ ██    ██ █████   ██    ██ ██████  ██    ██ ██████  ██   ██ ███████    ██    █████
         ██ ██   ██  ██  ██  ██      ██    ██ ██   ██ ██    ██ ██      ██   ██ ██   ██    ██    ██
    ███████ ██   ██   ████   ███████  ██████  ██   ██  ██████  ██      ██████  ██   ██    ██    ███████
    */

    saveOrUpdate = async () => {
        console.log(this.state.entitas);
        try {
            const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employment-relationship", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify(this.state.entitas)
            })
            if (resp.status != 200) {
                if(resp.data?.fullMessage)
                    console.log(resp.data.fullMessage);
                alert(resp.data.message)
            } else {
                const EmploymentRelationship=resp.data
                this.setState(
                    {
                        entitas: resp.data,
                        saved: resp.data.id?true:false
                    },()=>{
                        if (this.onSaveOrUpdateListener) 
                            this.onSaveOrUpdateListener(resp.data)
                    })
            }
        } catch(error) {
            if(error.response?.data?.fullMessage)
                console.log(error.response.data.fullMessage);
            alert(error.response.data.message) 
        }
    }
    



    /*
    ██████  ███████ ███    ██ ██████  ███████ ██████
    ██   ██ ██      ████   ██ ██   ██ ██      ██   ██
    ██████  █████   ██ ██  ██ ██   ██ █████   ██████
    ██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
    ██   ██ ███████ ██   ████ ██████  ███████ ██   ██
    */

    render() {

        /*
        ██    ██ ███████ ███████ ███████ ██████  ██       ██████  ██   ██     ███████ ███████  ██████  ███████ ██████
        ██    ██ ██         ███  ██      ██   ██ ██      ██    ██ ██  ██      ██      ██      ██       ██      ██   ██
        ██    ██ █████     ███   █████   ██████  ██      ██    ██ █████       ███████ █████   ██   ███ █████   ██   ██
         ██  ██  ██       ███    ██      ██   ██ ██      ██    ██ ██  ██           ██ ██      ██    ██ ██      ██   ██
          ████   ███████ ███████ ███████ ██   ██ ███████  ██████  ██   ██     ███████ ███████  ██████  ███████ ██████


         ██████  ██    ██  █████  ██████  ████████  ██████       ██  █████
        ██        ██  ██  ██   ██ ██   ██    ██    ██    ██      ██ ██   ██
        ██   ███   ████   ███████ ██████     ██    ██    ██      ██ ███████
        ██    ██    ██    ██   ██ ██   ██    ██    ██    ██ ██   ██ ██   ██
         ██████     ██    ██   ██ ██   ██    ██     ██████   █████  ██   ██
        */

        const createTextField = ({p,l,d,onChangeHandler,...rest}) => {
            return (
                <TextField1
                    label={l}
                    disabled={d?d:false}
                    value={this.state.entitas?.[p] || ""}
                    onChange={onChangeHandler?onChangeHandler:e => {
                        console.log("Megvaltozott")
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [p]: e.target.value },
                                saved: false
                            })
                        );
                    }}
                    {...rest}
                />)
        }

        const createTextFieldWithUnit = ({p,l,d,unit,onChangeHandler,...rest}) => {
            return (
                <TextFieldWithUnit1
                    label={l}
                    unit={unit}
                    disabled={d?d:false}
                    value={this.state.entitas?.[p] || ""}
                    onChange={onChangeHandler?onChangeHandler:e => {
                        console.log("Megvaltozott")
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [p]: e.target.value },
                                saved: false
                            })
                        );
                    }}
                    {...rest}
                />)
        }        

        const createTextArea = ({p,l,d,onChangeHandler,...rest}) => {
            return (
                <TextArea1
                    label={l}
                    value={this.state.entitas?.[p] || ""}
                    onChange={onChangeHandler?onChangeHandler:e => {
                        console.log("Megvaltozott")
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [p]: e.target.value },
                                saved: false
                            })
                        );
                    }}
                />)
        }     
        
        const createDatePicker= ({label,prop,onChangeHandler}) => {
            return(
                <DatePicker1
                    label={label}
                    value={this.state.entitas?.[prop]?dayjs(this.state.entitas?.[prop]):null}
                    onChange={onChangeHandler?onChangeHandler:v => {
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [prop]: v!=null?dayjs(v).format('YYYY-MM-DD'):null },
                                saved: false
                            })
                        );
                    }}
                />
            )
        }



        /*
        ██████  ███████ ████████ ██    ██ ██████  ███    ██
        ██   ██ ██         ██    ██    ██ ██   ██ ████   ██
        ██████  █████      ██    ██    ██ ██████  ██ ██  ██
        ██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██
        ██   ██ ███████    ██     ██████  ██   ██ ██   ████
        */

        return (
            <>
                <h2 className="mb-4 pb-2">
                
                { !this.state.entitas.id &&
                    "New employment relationship"
                }
                { this.state.entitas.id &&
                <Popover popovertext={"ID: "+this.state.entitas.id}>
                    Employment relationship 
                </Popover>
                }
                
                
                </h2>
                <div style={{ border: "solid 1px lightgray", padding: "20px", borderRadius: "20px", backgroundColor:"#fafafa", }}>
                    
                    {
                        this.state.entitas.active?
                            <Alert  className="my-2"  severity="success">ACTIVE</Alert>:
                            <Alert  className="my-2" severity="error">INACTIVE</Alert>
                                                
                    }

                    <form>
                            {/*
                            ███    ███ ██    ██ ███    ██ ██   ██  █████  ████████  █████  ██████  ███████     ██    ██  █████  ██       █████  ███████ ███████ ████████  ██████
                            ████  ████ ██    ██ ████   ██ ██  ██  ██   ██    ██    ██   ██ ██   ██ ██          ██    ██ ██   ██ ██      ██   ██ ██         ███     ██    ██    ██
                            ██ ████ ██ ██    ██ ██ ██  ██ █████   ███████    ██    ███████ ██████  ███████     ██    ██ ███████ ██      ███████ ███████   ███      ██    ██    ██
                            ██  ██  ██ ██    ██ ██  ██ ██ ██  ██  ██   ██    ██    ██   ██ ██   ██      ██      ██  ██  ██   ██ ██      ██   ██      ██  ███       ██    ██    ██
                            ██      ██  ██████  ██   ████ ██   ██ ██   ██    ██    ██   ██ ██   ██ ███████       ████   ██   ██ ███████ ██   ██ ███████ ███████    ██     ██████
                            */}
                            <Row>
                                <Col className="col-12 p-2 pb-3 pb-sm-2">
                                    <EmployeeSelection
                                        value={this.state.entitas.employee || null}
                                        onChangeHandler={
                                            (event,newEmployee)=>{
                                                this.setState(
                                                    prevstate => ({
                                                        entitas: { 
                                                            ...prevstate.entitas, 
                                                            employee: newEmployee,
                                                        },
                                                        saved: false
                                                    })
                                                );
                                            }
                                        }
                                        disabled={this.employeeID!=null}
                                    />
                                </Col>
                            </Row>
                            {/*
                            ██   ██ ██      ██ ███████ ███    ██ ███████     ██    ██  █████  ██       █████  ███████ ███████ ████████  ██████
                            ██  ██  ██      ██ ██      ████   ██ ██          ██    ██ ██   ██ ██      ██   ██ ██         ███     ██    ██    ██
                            █████   ██      ██ █████   ██ ██  ██ ███████     ██    ██ ███████ ██      ███████ ███████   ███      ██    ██    ██
                            ██  ██  ██      ██ ██      ██  ██ ██      ██      ██  ██  ██   ██ ██      ██   ██      ██  ███       ██    ██    ██
                            ██   ██ ███████ ██ ███████ ██   ████ ███████       ████   ██   ██ ███████ ██   ██ ███████ ███████    ██     ██████
                            */}
                            <Row>
                                <Col className="col-12 p-2 pb-3 pb-sm-2">
                                    <ClientSelection
                                        value={this.state.entitas.client || null}
                                        onChangeHandler={
                                            (event,newClient)=>{
                                                this.setState(
                                                    prevstate => ({
                                                        entitas: { 
                                                            ...prevstate.entitas, 
                                                            client: newClient,
                                                            clientPosition: null
                                                        },
                                                        saved: false
                                                    })
                                                );
                                            }
                                        }
                                    />
                                </Col>
                            </Row>   

                            {
                                this.state.entitas.client &&
                                <p className="my-2">Local date in client timezone: {this.state.entitas.client.currentLocalDateInTimeZone}
                                &nbsp;({this.state.entitas.client.timeZone})
                                </p>
                            } 

                            {/*
                            ██   ██  ██████  ████████ ███████ ██          ██████   ██████  ███████ ██ ████████ ██  ██████  ███    ██
                            ██   ██ ██    ██    ██    ██      ██          ██   ██ ██    ██ ██      ██    ██    ██ ██    ██ ████   ██
                            ███████ ██    ██    ██    █████   ██          ██████  ██    ██ ███████ ██    ██    ██ ██    ██ ██ ██  ██
                            ██   ██ ██    ██    ██    ██      ██          ██      ██    ██      ██ ██    ██    ██ ██    ██ ██  ██ ██
                            ██   ██  ██████     ██    ███████ ███████     ██       ██████  ███████ ██    ██    ██  ██████  ██   ████


                            ██    ██  █████  ██       █████  ███████ ███████ ████████  ██████
                            ██    ██ ██   ██ ██      ██   ██ ██         ███     ██    ██    ██
                            ██    ██ ███████ ██      ███████ ███████   ███      ██    ██    ██
                             ██  ██  ██   ██ ██      ██   ██      ██  ███       ██    ██    ██
                              ████   ██   ██ ███████ ██   ██ ███████ ███████    ██     ██████
                            */}

                            <Row>
                                <Col className="col-12 p-2 pb-3 pb-sm-2">
                                    <ClientPositionSelection
                                        value={this.state.entitas?.clientPosition || null}
                                        clientID={this.state.entitas?.client?.id}
                                        key={"client-position-selection"+this.state.entitas?.client?.id}
                                        onChangeHandler={
                                            (event,newClientPosition)=>{
                                                this.setState(
                                                    prevstate => ({
                                                        entitas: { 
                                                            ...prevstate.entitas, 
                                                            clientPosition: newClientPosition,
                                                        },
                                                        saved: false
                                                    })
                                                );
                                            }
                                        }
                                    />
                                </Col>
                            </Row>   


                     


                            {/*
                            ███████ ████████  █████  ██████  ████████        ███████ ████████  ██████  ██████      ██████   █████  ████████ ███████
                            ██         ██    ██   ██ ██   ██    ██           ██         ██    ██    ██ ██   ██     ██   ██ ██   ██    ██    ██
                            ███████    ██    ███████ ██████     ██           ███████    ██    ██    ██ ██████      ██   ██ ███████    ██    █████
                                 ██    ██    ██   ██ ██   ██    ██                ██    ██    ██    ██ ██          ██   ██ ██   ██    ██    ██
                            ███████    ██    ██   ██ ██   ██    ██    ▄█     ███████    ██     ██████  ██          ██████  ██   ██    ██    ███████
                            */}

                            {/*
                            ██   ██  ██████  ██    ██ ██████  ██      ██    ██     ██████   █████  ████████ ███████
                            ██   ██ ██    ██ ██    ██ ██   ██ ██       ██  ██      ██   ██ ██   ██    ██    ██
                            ███████ ██    ██ ██    ██ ██████  ██        ████       ██████  ███████    ██    █████
                            ██   ██ ██    ██ ██    ██ ██   ██ ██         ██        ██   ██ ██   ██    ██    ██
                            ██   ██  ██████   ██████  ██   ██ ███████    ██        ██   ██ ██   ██    ██    ███████ ▄█


                             ██████  ██    ██ ███████ ██████  ████████ ██ ███    ███ ███████
                            ██    ██ ██    ██ ██      ██   ██    ██    ██ ████  ████ ██
                            ██    ██ ██    ██ █████   ██████     ██    ██ ██ ████ ██ █████
                            ██    ██  ██  ██  ██      ██   ██    ██    ██ ██  ██  ██ ██
                             ██████    ████   ███████ ██   ██    ██    ██ ██      ██ ███████
                            */}

                            <Row>
                                <Col className="col-12 col-sm-6 col-lg-4 p-2 ">
                                    {createDatePicker({
                                        prop:"beginning",
                                        label:"beginning"})}
                                </Col> 
                                <Col className="col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                    {createTextFieldWithUnit({
                                        p:"hourlyRate",
                                        l:"hourly rate",
                                        unit:"$",
                                        unitPosition:"start",
                                        onChangeHandler:event => {
                                            console.log("Megvaltozott")
                                            const newValue= event.target.value
                                            this.setState(
                                                prevstate => ({
                                                    entitas: { 
                                                        ...prevstate.entitas, 
                                                        hourlyRate: newValue,
                                                        overTime: UtilsVSM.calculateOfficalOvertime(newValue)
                                                    },
                                                    saved: false
                                                })
                                            )
                                        }                                       




                                    })}
                                </Col>   
                                <Col className="col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                    {createTextFieldWithUnit({
                                        p:"overTime",
                                        l:"overtime",
                                        unit:"$",
                                        unitPosition:"start",
                                        d:true
                                    })}
                                </Col>    
                                <Col className="col-12 col-sm-6 col-lg-4 p-2 ">
                                    {createDatePicker({
                                        prop:"ending",
                                        label:"ending"})}
                                </Col>
                            </Row>


                            



                           
   

                            {/*
                             ██████  ██████  ███    ███ ███    ███ ███████ ███    ██ ████████
                            ██      ██    ██ ████  ████ ████  ████ ██      ████   ██    ██
                            ██      ██    ██ ██ ████ ██ ██ ████ ██ █████   ██ ██  ██    ██
                            ██      ██    ██ ██  ██  ██ ██  ██  ██ ██      ██  ██ ██    ██
                             ██████  ██████  ██      ██ ██      ██ ███████ ██   ████    ██
                            */}
                            <Row>
                                <Col className="col-12 p-2">
                                    {createTextArea({p:"comment",l:"comment"})}
                                </Col>
                            </Row>

                            {/*
                            ██████   ██████  ███    ██ ██    ██ ███████     ███████ ████████  █████  ██████  ████████
                            ██   ██ ██    ██ ████   ██ ██    ██ ██          ██         ██    ██   ██ ██   ██    ██
                            ██████  ██    ██ ██ ██  ██ ██    ██ ███████     ███████    ██    ███████ ██████     ██
                            ██   ██ ██    ██ ██  ██ ██ ██    ██      ██          ██    ██    ██   ██ ██   ██    ██
                            ██████   ██████  ██   ████  ██████  ███████     ███████    ██    ██   ██ ██   ██    ██    ▄█


                            ██████   █████  ██    ██ ███████         █████  ███    ███  ██████  ██    ██ ███    ██ ████████
                            ██   ██ ██   ██  ██  ██  ██             ██   ██ ████  ████ ██    ██ ██    ██ ████   ██    ██
                            ██   ██ ███████   ████   ███████        ███████ ██ ████ ██ ██    ██ ██    ██ ██ ██  ██    ██
                            ██   ██ ██   ██    ██         ██        ██   ██ ██  ██  ██ ██    ██ ██    ██ ██  ██ ██    ██
                            ██████  ██   ██    ██    ███████ ▄█     ██   ██ ██      ██  ██████   ██████  ██   ████    ██
                            */}


                            <Row>
                                <Col className="col-12 col-sm-6 col-lg-4 p-2 ">
                                    {createDatePicker({
                                        prop:"bonusBeginning",
                                        label:"bonus beginning"})}
                                </Col>
                                <Col className="col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                    {createTextFieldWithUnit({
                                        p:"bonusDays",
                                        l:"bonus days",
                                        unit:"day(s)"
                                    })}
                                </Col>   
                                <Col className="col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                    {createTextFieldWithUnit({
                                        p:"bonusAmount",
                                        l:"bonus amount",
                                        unit:"$",
                                        unitPosition:"start"
                                    })}
                                </Col>    
                                <Col className="col-12 col-sm-6 col-lg-4 p-2 ">
                                    {createDatePicker({
                                        prop:"bonusEarlyTermination",
                                        label:"bonus early termination"})}
                                </Col>                                                                                          
                            </Row>

                    </form>


                    <Row className="justify-content-between">
                        {
                            this.additionalButton &&
                            <Col>
                                    {React.cloneElement(this.additionalButton,{ className: "m-2 ms-0 mt-3" })}
                            </Col>
                        }
                        <Col className="col-auto">
                            <Button
                                onClick={this.saveOrUpdate}
                                className="m-2 ms-0 mt-3"
                                variant={this.state.saved?"primary":"danger"}
                            >
                                {this.state.entitas?.id ? "Save item" : "Create new item"}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}