import { Autocomplete } from '@mui/material';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Row,Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import LoopIcon from '@mui/icons-material/Loop';
import * as UtlsVSM from "../../../UtilsVSM"

export default function ClientPositionSelection({
    value,
    clientID,
    onChangeHandler
}) {

    const [clientPositions, setClientPositions] = useState([]);

    const loadClientPositions= async () => {
        if(clientID) {
            const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH + `/api/clients/${clientID}/positions`)
            const clientPositions=resp.data
            UtlsVSM.objectArrayMultipleSort(clientPositions,[{prop:"name",direction:"asc"},{prop:"id",direction:"asc"}])
            setClientPositions(clientPositions);
        } else {
            setClientPositions([]);
        }
    }

    useEffect(() => {
        loadClientPositions()
    }, []);


    return (
        
        <Row className="align-items-center">
            <Col>
                <Autocomplete
                    key={"client-position-selection-autocomplete"+clientID}
                    isOptionEqualToValue={(option, value) => {return option!=null && value!=null && option.id == value.id}}
                    options={clientPositions}
                    getOptionLabel={clientPosition => { return (`${clientPosition.name} (ID: ${clientPosition.id})`)}}
                    size="small"
                    onChange={onChangeHandler}
                    value={value}
                    renderInput={(params) => (
                        <TextField {...params} label="ClientPosition" variant="outlined" />
                    )}
                />
            </Col>
            <Col className="col-auto">
                <LoopIcon style={{cursor:"pointer"}} onClick={()=>{loadClientPositions()}} />
            </Col>
        </Row>
    );
}