import { useEffect,useState } from 'react';
import Form from 'react-bootstrap/Form';

export default function BoostrapFormControl({
    prop,
    entitas,
    onChangeHandler,
    label,
    defaultValue,
    ...rest // Az összes többi propot elmentjük a rest objektumba
}) {
    const ev=entitas?.[prop]
    const [inputState,setInputState]=useState(
        ev==null || ev==undefined ? defaultValue:ev
    )

    useEffect(() => {
        setInputState(ev == null || ev == undefined ? defaultValue : ev);
    }, [ev]);    

    return(<>
        <Form.Control 
            type="text" 
            placeholder={label}
            maxLength={255}  
            name={prop}               
            value={inputState}
            onChange={event => {
                const value=event.target.value;
                setInputState(value)
                if(onChangeHandler) onChangeHandler({entitas,prop,event,value})
            }}
            {...rest} // Itt hozzáadjuk a többi propot az input elemhez
        />
    </>)
}