import React from 'react';
import ReactDOM from 'react-dom';
import { useState } from 'react'
import { Diamond } from '../assets/icons/Diamond';
import { BarChart } from '../assets/icons/BarChart';
import { Global } from '../assets/icons/Global';
import { InkBottle } from '../assets/icons/InkBottle';
import { Book } from '../assets/icons/Book';
import { Calendar } from '../assets/icons/Calendar';
import { ShoppingCart } from '../assets/icons/ShoppingCart';
import { Service } from '../assets/icons/Service';
import { ProSidebarProvider } from 'react-pro-sidebar';
import * as kcutils from "../Utils/keycloakutil"
import { useKeycloak } from '@react-keycloak/web';
import Sidebar1 from '../Navbar/Sidebar1';
import axios from 'axios';
import LogoutIcon from "../assets/icons/LogoutIcon"
import { HamburgerMenu } from "../assets/icons/HamburgerMenu"
import {useProSidebar} from 'react-pro-sidebar';
import { sayHello } from '../../../src-kozos/src/tmp/imp';

export default function Layout1({ children }) {
  return (
    <div className="kontener" >
      <Sidebar1 className="bal" />
      <div className="jobb">
        <div style={{ paddingTop: "1px", paddingRight: "20px" }}><Header /></div>
        <div style={{ padding: "10px" }}>{children}</div>
        <div style={{ padding: "20px" }}>{<Footer></Footer>}</div>
      </div>
    </div>
  )
}

export function  Header() {
  const { keycloak } = useKeycloak();
  const handleLogout = (e) => {
    e.preventDefault()
    axios.defaults.headers.common['Authorization'] = "";
    keycloak.logout();
  };

  const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();

  const menuToggle=()=> {
    console.log("menu toggle");
    toggleSidebar()
  }

  

  return (
    <div className="header" style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
    {broken && <a href="#" onClick={menuToggle} style={{ textAlign: "left",fontSize:"0",paddingRight:"10px" }}>
      <HamburgerMenu />
    </a>}
    <div style={{ flexGrow: "1", textAlign: "left" }}>VSM-APP-ADMIN
      </div>
      <div><span style={{whiteSpace:"nowrap"}}>{keycloak.tokenParsed.preferred_username}</span></div>
      <a onClick={handleLogout} href="#" title="logout" alt="logout" style={{fontSize:"0"}}><LogoutIcon size="20" style={{ marginLeft: "20px" }} color="#84191c" /></a>
    </div>
  )
}

export function  Footer() {
  return (
    <>
      <hr />
      <div className="footer">
        Vau Staffing © 2009-{new Date().getFullYear()}. All rights reserved.
      </div>
    </>
  )
}




