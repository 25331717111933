import { Autocomplete } from '@mui/material';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Row,Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import LoopIcon from '@mui/icons-material/Loop';
import * as UtlsVSM from "../../../UtilsVSM"

export default function EmploymentRelationshipSelection({
    value,
    employeeID,
    onChangeHandler
}) {

    console.log("EmploymentRelationshipSelection");
    console.log("employeeID: "+employeeID);
    
    const [employmentRelationships, setEmploymentRelationships] = useState([]);

    const loadEmploymentRelationships= async () => {
        if(employeeID) {
            const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH + `/api/employees/${employeeID}/employment-relationships`)
            const employmentRelationships=resp.data
            console.log("EmploymentRelationshipSelection-loadEmploymentRelationships");
            console.log(employmentRelationships);
            UtlsVSM.objectArrayMultipleSort(employmentRelationships,[{prop:"id",direction:"asc"}])
            setEmploymentRelationships(employmentRelationships);
        } else {
            setEmploymentRelationships([]);
        }
    }

    useEffect(() => {
        loadEmploymentRelationships()
    }, []);


    return (
        
        <Row className="align-items-center">
            <Col>
                <Autocomplete
                    key={"employment-relationship-selection-autocomplete"+employeeID}
                    isOptionEqualToValue={(option, value) => {return option!=null && value!=null && option.id == value.id}}
                    options={employmentRelationships}
                    getOptionLabel={employmentRelationship => { 
                        return (
                            `${employmentRelationship.client?.companyName} - ` + 
                            `${employmentRelationship?.clientPosition?.name} - ` + 
                            `${employmentRelationship?.employee?.firstName} - ` + 
                            `${employmentRelationship?.employee?.lastName} - ` + 
                            
                            `(ID: ${employmentRelationship.id})`)}}
                    size="small"
                    onChange={onChangeHandler}
                    value={value}
                    renderInput={(params) => (
                        <TextField {...params} label="employment relationship" variant="outlined" />
                    )}
                />
            </Col>
            <Col className="col-auto">
                <LoopIcon style={{cursor:"pointer"}} onClick={()=>{loadEmploymentRelationships()}} />
            </Col>
        </Row>
    );
}