import { Autocomplete } from '@mui/material';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Row,Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import LoopIcon from '@mui/icons-material/Loop';



// Az adatforrásunk, egy munkatársokat tartalmazó tömb
/*const employees = [
  { id: 1, name: 'John Doe', title: 'Developer' },
  { id: 2, name: 'Jane Doe', title: 'Designer' },
  // ...
];
*/

/**
 * @module EmployeeSelection
 */

/**
 * Leiras
 * @function EmployeeSelection
 * @param {any} {value
 * @param {any} onChangeHandler}
 * @returns {any}
 */
export default function EmployeeSelection({
    value,
    onChangeHandler,
    label="Employee",
    disabled=false
}) {

    const [employees, setEmployees] = useState([]);

    const loadEmployees= async () => {
        const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH + "/api/employee/all")
        const data=resp.data
        console.log(data);
        setEmployees(data);
    }

    useEffect(() => {
        loadEmployees()
    }, []);


    return (
        
        <Row className="align-items-center">
            <Col>
                <Autocomplete
                    isOptionEqualToValue={(option, value) => {return option!=null && value!=null && option.id == value.id}}
                    options={employees}
                    getOptionLabel={employee => { return (`${employee.firstName} ${employee.lastName} (ID: ${employee.id}, SSN: ${employee.socialSecurityNumber || "-"})`)}}
                    size="small"
                    onChange={onChangeHandler}
                    value={value}
                    renderInput={(params) => (
                        <TextField {...params} label={label} variant="outlined" />
                    )}
                    disabled={disabled}
                />
            </Col>
            <Col className="col-auto">
                <LoopIcon style={{cursor:"pointer"}} onClick={()=>{loadEmployees()}} />
            </Col>
        </Row>
    );
}