import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export default function DatePicker1({
    className = "w-100",
    disabled,
    labelAnimation=false, // A cimke nem ugrik be
    style={ backgroundColor: "white" },
    size="small",
    label,
    clearable=true,
    ...rest
}) {

    return (
        <DatePicker



            style={style}

            label={label}
            slotProps={{ 
                textField: { size: size },
                ...(clearable ? {actionBar: {actions: ['clear']}} : {}) 
            }}

            className={className}
            disabled={disabled}
            
            onAccept={(newDate) => {
                console.log(newDate);
            }}

            {...rest}
        />
    )
}