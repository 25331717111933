import { Autocomplete } from '@mui/material';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Row,Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import LoopIcon from '@mui/icons-material/Loop';
import * as UtlsVSM from "../../../UtilsVSM"



// Az adatforrásunk, egy munkatársokat tartalmazó tömb
/*const clients = [
  { id: 1, name: 'John Doe', title: 'Developer' },
  { id: 2, name: 'Jane Doe', title: 'Designer' },
  // ...
];
*/

export default function ClientSelection({
    value,
    onChangeHandler
}) {

    const [clients, setClients] = useState([]);

    const loadClients= async () => {
        const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH + "/api/client/all")
        const clients=resp.data
        UtlsVSM.objectArrayMultipleSort(clients,[{prop:"companyName",direction:"asc"}])
        console.log(clients);
        setClients(clients);
    }

    useEffect(() => {
        loadClients()
    }, []);


    return (
        
        <Row className="align-items-center">
            <Col>
                <Autocomplete
                    isOptionEqualToValue={(option, value) => {return option!=null && value!=null && option.id == value.id}}
                    options={clients}
                    getOptionLabel={client => { return (`${client.companyName} - ${client.city} (ID: ${client.id})`)}}
                    size="small"
                    onChange={onChangeHandler}
                    value={value}
                    renderInput={(params) => (
                        <TextField {...params} label="Client" variant="outlined" />
                    )}
                />
            </Col>
            <Col className="col-auto">
                <LoopIcon style={{cursor:"pointer"}} onClick={()=>{loadClients()}} />
            </Col>
        </Row>
    );
}