import React from 'react'
import ReactDOM from 'react-dom/client'

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Minusz from "../assets/minus-cirlce-svgrepo-com.svg"
import UserAdd from "../assets/user-add-svgrepo-com.svg"
import UserLinked from "../assets/link-h-svgrepo-com.svg"
import TextField from '@mui/material/TextField';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';



export default class ClientTeammateController extends React.Component {
    constructor(props) {
        super(props);
        this.index = this.props.index
        this.onPluszFigyelo = this.props.onPluszFigyelo
        this.onMinuszFigyelo = this.props.onMinuszFigyelo
        this.onValtozasFigyelo = this.props.onValtozasFigyelo
        this.onTeammateEntitasValtozasFigyelo = this.props.onTeammateEntitasValtozasFigyelo
        this.state = {
            teammate: this.props.teammate,
            saved: this.props.teammate.id ? true : false,
            userlinkprogress: false
        };
    }

    useradd = async () => {
        this.setState({ userlinkprogress: true })
        //console.log(this.state.teammate.id);
        console.log(this.state.teammate.id);
        const registration = {
            clientTeammateID: this.state.teammate.id,
            email: this.state.teammate.email,
            firstName: this.state.teammate.firstName,
            lastName: this.state.teammate.lastName
        }
        console.log(registration);
        try {
            const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/keycloak/user-registration-for-client-teammate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify(registration)
            })
            const data=resp.data
            this.setState({ userlinkprogress: false },()=>{
                if (resp.status != 200) {
                    alert(data.message)
                    console.log(data.fullMessage);
                } else {
                    console.log("Sikeres link");
                    console.log(resp.data);
                    this.onTeammateEntitasValtozasFigyelo({
                        index: this.index,
                        entitas: data
                    })              
                }
            })
        } catch (error) {
            this.setState({ userlinkprogress: false })
            if (error?.response?.data?.message)
                alert(error.response.data.message);
            else
                alert(error.response.data);
            if (error?.response?.data?.fullMessage) {
                alert("More info is on console.")
                console.log(error.response.data.fullMessage);
            }
        }
    }

    componentDidMount = () => {
        console.log("Mount");
    }

    render() {
        let inputs = []
        const isKeycloakUserAssigned = this.state.teammate?.kcuserid ? true : false
        if (this.state.teammate) {
            inputs = [
                { p: "firstName", l: "first name", disabled: isKeycloakUserAssigned },
                { p: "lastName", l: "last name", disabled: isKeycloakUserAssigned },
                { p: "title", l: "title", disabled: false },
                { p: "phone", l: "phone", disabled: false },
                { p: "email", l: "email", disabled: isKeycloakUserAssigned }
            ].map((e, i) => {
                const keyprefix = [
                    "teammate-controller",
                    this.state.teammate.id || this.state.teammate.tmpid,
                    e.p
                ].join("-")
                return <Col className="align-self-center p-1 pt-md-2 col-12 col-sm-6 col-lg-3 mb-2 mb-lg-0" key={keyprefix + "-col"}>
                    <TextField
                        className="w-100"
                        key={keyprefix + "-input"}
                        disabled={e.disabled}
                        label={e.p}
                        id={keyprefix + "-input"}
                        value={this.state.teammate[e.p] || ""}
                        onChange={event => {
                            this.setState(
                                prevstate => ({
                                    teammate: { ...prevstate.teammate, [e.p]: event.target.value },
                                    saved: false
                                }),
                                () => {
                                    console.log("Valtozas")
                                    if (this.onValtozasFigyelo)
                                        this.onValtozasFigyelo(
                                            {
                                                index: this.index,
                                                tulajdonsag: e.p,
                                                ujertek: event.target.value,
                                                entitas: this.state.teammate
                                            })
                                }
                            );
                        }}
                        size="small"
                    />
                </Col>
            })
        }

        return (
            <div className="p-1 mb-3" style={{ width: "100%", border: "solid 1px lightgray", borderRadius: "5px", background: "rgba(0,0,0,0.05" }}>

                <Row className="m-0 pt-2 pt-md-0" style={{ width: "100%" }}>
                    {this.state.teammate && inputs}
                    {[
                        { prop: "invoiceHandler", labelshort: "invoice", label: "invoice handler" },
                        { prop: "timecardHandler", labelshort: "timecard", label: "timecard handler" }
                    ].map((e, i) => {
                        return (
                            <Col className="col-auto align-self-center p-2 pb-1 pe-2 mb-2 mb-lg-0" key={i + "-switch-" + e.prop}>
                                <Form.Check
                                    className=""
                                    style={{ display: "inline-block" }}
                                    type="switch"
                                    label={e.labelshort}
                                    title={e.label}
                                    checked={this.state.teammate[e.prop]}
                                    onChange={ce => {
                                        this.setState(
                                            prevstate => ({ teammate: { ...prevstate.teammate, [e.prop]: ce.target.checked } }),
                                            () => {
                                                if (this.onValtozasFigyelo)
                                                    this.onValtozasFigyelo(
                                                        {
                                                            index: this.index,
                                                            tulajdonsag: e.prop,
                                                            ujertek: ce.target.checked,
                                                            entitas: this.state.teammate
                                                        })
                                            }
                                        );
                                    }}

                                />
                            </Col>)
                    })}
                    <Col className="col-auto align-self-center p-0 mb-2 mb-lg-0">
                        <a onClick={e => { if (false && !confirm("Confirm?")) return; this.onMinuszFigyelo && this.onMinuszFigyelo(this.index) }}>
                            <img src={Minusz} style={{ width: "25px" }} />
                        </a>
                    </Col>
                    {this.state.teammate?.kcuserid &&
                        <Col className="col-auto align-self-center p-0 mb-2 mb-lg-0 ms-2">
                            <img
                                onClick={()=>{
                                    navigator.clipboard.writeText(this.state.teammate.kcuserid);
                                    alert("Keycloak user ID is copied to the clipboard!")
                                }}
                                src={UserLinked}
                                style={{ width: "25px" }}
                                alt={"Keycloak user id: " + this.state.teammate.kcuserid}
                                title={"Keycloak user id: " + this.state.teammate.kcuserid}
                            />
                        </Col>
                    }

                    {
                        !this.state.teammate?.kcuserid && this.state.saved && (
                            this.state.userlinkprogress ?
                                <Col className="col-auto align-self-center p-0 mb-2 mb-lg-0 ms-2">
                                    <CircularProgress size={30} />
                                </Col>
                                :
                                <Col className="col-auto align-self-center p-0 mb-2 mb-lg-0 ms-2">
                                    <a onClick={e => { if (false && !confirm("Confirm?")) return; this.useradd() }}>
                                        <img
                                            src={UserAdd}
                                            style={{ width: "25px" }}
                                            alt={"Teammate id: " + this.state.teammate.id}
                                            title={"Teammate id: " + this.state.teammate.id}
                                        />
                                    </a>
                                </Col>
                        )
                    }

                </Row>

            </div>


        );
    }
}