import React from 'react'
import ReactDOM from 'react-dom/client'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import axios from 'axios';
import {getNestedValue} from "../UtilsVSM"
import Popover from "../comps/Popover"

export default class ClientTeamMateAdatlap extends React.Component {
    constructor(props) {
        super(props);
        this.id = props.id

        this.onSaveOrUpdateListener = props.onSaveOrUpdate
        this.entitas = this.props.entitas
        this.state = {
            entitas: {
                id: null
            },
            invoiceHandlerController: false,
            timecardHandlerController: false
        };
        this.formRef = React.createRef()
    }

    loadEntitas = async (id) => {
        const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/client-team-mate/" + id)
        const entitas = resp.data
        console.log(entitas);
        this.setState({
            entitas: entitas,
            invoiceHandlerController: entitas.invoiceHandler,
            timecardHandlerController: entitas.timecardHandler
        })
    }

    componentDidMount = () => {
        if (!this.entitas && this.id) this.loadEntitas(this.id)
    }

    saveOrUpdate = async () => {
        const formData = new FormData(this.formRef.current);
        let json = {};
        for (const [key, value] of formData.entries()) {
            json[key] = value;
        }
        json = { ...this.state.entitas, ...json }
        if (this.state.entitas.id) json.id = this.state.entitas.id
        json.invoiceHandler = this.state.invoiceHandlerController
        json.timecardHandler = this.state.timecardHandlerController
        console.log(JSON.stringify(json));
        const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/client-team-mate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify(json)
        })
        const e = resp.data
        this.setState({ entitas: e })
        if (this.onSaveOrUpdateListener) this.onSaveOrUpdateListener(e)
    }


    render() {

        const mezok=[
            "title", "name", "phone", 
            {p:"email",l:"E-mail",disabled:this.state.entitas && this.state.entitas.kcuserid},
            {p:"kcuserid",l:"Keycloak user ID",disabled:true},
            {p:"client.companyName",l:"Client",disabled:true},
        ].map((e,i)=>{
            return typeof e==="string"? 
                <Form.Group className="mb-3" controlId={e} key={i}>
                    <Form.Label>{e}</Form.Label>
                    <Form.Control type="text" placeholder={e} name={e}
                        defaultValue={this.state.entitas && this.state.entitas[e]} />
                </Form.Group>
            :
                <Form.Group className="mb-3" controlId={e.p} key={i}>
                    <Form.Label>{e.l}</Form.Label>
                    <Form.Control type="text" placeholder={e.p} name={e.p} disabled={e.disabled}
                        defaultValue={getNestedValue(this.state.entitas,e.p)} />
                </Form.Group>
        })

       


        return (
            <div style={{ border: "solid 1px lightgray", padding: "20px", borderRadius: "20px" }}>
                <h2>
                    <Popover popovertext={this.state.entitas.id}>
                        Client teammate
                    </Popover>
                </h2>
                <hr />
                <form ref={this.formRef}>
                    {mezok}
                    <Form.Check
                        className="m-2"
                        style={{ display: "inline-block" }}
                        type="switch"
                        id="custom-switch"
                        name="invoiceHandler"
                        label="Invoice handler"
                        checked={this.state.invoiceHandlerController}
                        onChange={e => this.setState({ invoiceHandlerController: e.target.checked })}
                    />

                    <br />
                    <Form.Check
                        className="m-2"
                        style={{ display: "inline-block" }}
                        type="switch"
                        id="custom-switch"
                        name="timecardHandler"
                        label="timecard handler"
                        checked={this.state.timecardHandlerController}
                        onChange={e => this.setState({ timecardHandlerController: e.target.checked })}
                    />



                </form>
                <Button
                    onClick={this.saveOrUpdate}
                    className="m-2 ms-0"
                    variant="primary"
                    defaultValue={this.state.entitas.invoiceHandler}
                >
                    {this.state.entitas?.id ? "Save item" : "Create new item"}
                </Button>
            </div>
        );
    }
}