import React from 'react'
import ReactDOM from 'react-dom/client'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import { nanoid } from 'nanoid'
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import axios from 'axios';

export default class UserAdatlap extends React.Component {
    constructor(props) {
        super(props);
        this.id = props.id

        this.onSaveOrUpdateListener = props.onSaveOrUpdate
        this.entitas = this.props.entitas
        this.state = {
            entitas: {
                id: null
            },
            switch:false
        };
        this.formRef = React.createRef()
    }

    loadEntitas = async (id) => {
        const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/keycloak/users/" + id)
        const eResp = resp.data
        this.setState({
            entitas: eResp,
        })
    }

    componentDidMount = () => {
        if (!this.entitas && this.id) this.loadEntitas(this.id)
    }

    saveOrUpdate = async () => {
        const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/keycloak", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify(this.state.entitas)
        })
        // TODO 
        if (resp.status != 200)
            alert("Error, please watch the console.")
        else
            if (false) alert("Successful save")
        const eResp = resp.data
        this.setState(
            {
                entitas: eResp,
            })
        if (this.onSaveOrUpdateListener) this.onSaveOrUpdateListener(eResp)
    }

    sendLoginInformation = async () => {
        const id=this.state.entitas.id
        const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/keycloak/send-login-details/"+id) 
        if(resp.status!=200) {
            alert("Error")
        } else {
            alert("OK!")
        }
    }

    render() {

        return (
            <div style={{ border: "solid 1px lightgray", padding: "20px", borderRadius: "20px" }}>
                <h2>Employee (ID:{this.state.entitas.id ? this.state.entitas.id : " new item"})</h2>
                <hr />
                <form ref={this.formRef}>
                    <Row>
                        {
                            [
                                { p: "id", l: "ID", d: true },
                                { p: "username", l: "username", d: true },
                                { p: "email", l: "email", d: true },
                                { p: "firstName", l: "First name", d: true },
                                { p: "lastName", l: "Last name", d: true },

                            ].map(({ p, l, c ,d}, i) => {
                                return (
                                    <Col className="col-12 col-sm-6 mb-3" key={i}>
                                        <TextField
                                            id={"tf"+p}
                                            className="w-100"
                                            disabled={d}
                                            label={l}
                                            value={this.state.entitas[p] || ""}
                                            onChange={(e) => this.setState({ entitas: { ...this.state.entitas, [p]: e.target.value } })}
                                            size="small"
                                        />
                                    </Col>
                                )
                            }
                            )
                        }
                    </Row>
                </form>

                <FormGroup>
                        <Switch 
                            id="sw-1"
                            checked={this.state.entitas.enabled || false}
                            onChange={(e) => this.setState({ entitas: { ...this.state.entitas, enabled: e.target.checked } })}
                        />
               </FormGroup>



               <Button
                    onClick={this.sendLoginInformation}
                    className="m-2 ms-0 mt-5"
                    variant="primary"
                >
                    Send login information
                </Button>

                <Button
                    onClick={this.saveOrUpdate}
                    className="m-2 ms-0 mt-5"
                    variant="primary"
                >
                    {this.state.entitas?.id ? "Save item" : "Create new item"}
                </Button>
            </div>
        );
    }
}