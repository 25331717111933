import React from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { detect } from "detect-browser";
import EmployeeAdatlap from "./EmployeeAdatlap"
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import _ from 'lodash';
import axios from 'axios';
import {getNestedValue} from "../UtilsVSM"
import * as UtilsReactDataGridIO from "../UtilsReactDataGridIO"
import * as UtilsVSM from "../UtilsVSM"

import StringFilter from '@inovua/reactdatagrid-community/StringFilter'


export default class EmployeeTabla extends React.Component {
    constructor(props) {
        super(props);
        this.tablaKontextusNev = props.ktxname
        this.generateAmountRef=React.createRef();
        this.renderelo = 
            UtilsReactDataGridIO.createGeneralRenders({
                handleDatasheetClickEvent:this.onAkcioAdatlapClicked,
                handleDeleteClickEvent:this.onAkcioTorlesClicked}) 

        const tabledef=[

            ...[
                "id",
                {p:"firstName",l:"first name"},
                {p:"lastName",l:"last name"},
                {p:"email",l:"e-mail"},
                "phone","address","city","state",
                {p:"zipCode",l:"zip code"},
                {p:"apartment",l:"Apt"},
                {p:"socialSecurityNumber",l:"SSN"},
                {p:"referencePerson.id",l:"Ref. pers. ID"},
                {p:"referencePerson.firstName",l:"Ref. pers. first name"},
                {p:"referencePerson.lastName",l:"Ref. pers. last name"}
            ].map(e=>{
                return (typeof e =="object"? 
                    {prop:e.p, label:e.l, renderelo:"multiPropStringRender",szuro:"nested"}:
                    {prop:e, renderelo:"multiPropStringRender",szuro:"nested"})
            }),
        

            {prop:"edit",  renderelo:"datasheetRender"},
            {prop:"delete",renderelo:"removeRender"},
        ]

        console.log("Employee tábla: start!");

        this.kezdoAllapot = {
            verzio:UtilsVSM.isEnvDev()?UtilsVSM.randomInt({min:1,max:20}):1, // Ha valtozik a kezdoallapot(konfiguracio) akkor noveld ezt meg, hogy ne a regi "rosszat toltse be"
            columns: UtilsReactDataGridIO.createColumnDefinifitions(tabledef,this.renderelo),
            reservedViewportWidth: 0,
            enableFiltering: true,
            limit:10,
            backupverzio:1,
        }

        this.kezdoAllapot.columnOrder=this.kezdoAllapot.columns.map(e=>e.name)
        
        this.filterTypes = UtilsReactDataGridIO.filterTypes
        this.filterValue= UtilsReactDataGridIO.createFilterDefinifitions(tabledef)

        this.columnFilterSupported=true
        const browser = detect();

        if (browser && browser.os && 
            [
                "iPad Simulator",
                "iPhone Simulator",
                "iPod Simulator",
                "iPad",
                "Phone",
                "iPod",
                "IOS",
                "MAC"
            ].find(e => e.toLowerCase().includes(browser.os.toLowerCase()))            
        ) {
            const majorVersionFind = browser.version.match(/(\d+)\.\d+\.\d+/)
            const majorVersionOK=majorVersionFind[1] && majorVersionFind[1]>=16
            this.columnFilterSupported=
                browser.version.toLowerCase().includes("safari") && majorVersionOK
        }

        this.state = {
            allapot: this.kezdoAllapot,
            globalFilterText:"",
            selected: [],
            adatlap: {show:false,entitasid:null},
            confirmDeletionDialog: {show:false,entitasIDk:[]},
            dataSource: [],
            gridRef:null
        };
    }
   
    onAkcioAdatlapClicked=(id)=> {
        this.setState({adatlap:{show:true,entitasid:id}})
    }

    onAkcioTorlesClicked=(id)=> {
        this.setState({confirmDeletionDialog:{show:true,entitasIDk:[parseInt(id)]}})
    }    

    onSelectionChange = (e) => {
        if(e.selected===true) {
            let selected={}
            e.data.forEach((value, index) => {
                selected[value.id] = value;
            });
            this.setState({ 
                selected: selected
            })
        } else {
            this.setState({ 
                selected: e.selected
            })
        }
    }


    handleAdatlapShow = () => {
        this.setState({ adatlap: {show: true,entitasid:null} })
    }

    handleAdatlapClose = () => {
        this.setState({ adatlap: {show: false,entitasid:null} })
    }

    componentDidMount = () => {
        this.loadDatas()
        try {
            this.reloadTableStateFromLocalStorage()
        } catch (error) {
            this.setState({ allapot: this.kezdoAllapot })
        }
    }

    deletes = async () => {
        const IDs = this.state.confirmDeletionDialog.entitasIDk;

        const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employee", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify(IDs)
        })
        if(resp.status!=200) {
            console.log(await resp.data);
            alert("Error during deletion(s), please watch the console!")
            return;
        }        
        const modositas=this.state.dataSource.filter(e=>IDs.includes(e.id)?false:true).map(e=>e)
        this.setState({
            dataSource:modositas,
            selected: {},
            confirmDeletionDialog:{show:false,entitasok:[]}
        })
    }

    confirmDeletionDialogShow = (b) => {
        this.setState({ confirmDeletionDialog:{show:b,entitasIDk:b?Object.keys(this.state.selected).map(e=>parseInt(e)):[]}})
    }


    loadDatas = async () => {
        const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employee/all")
        const json = resp.data
        this.setState({ dataSource: json })
    }


    onColumnOrderChange = (columnOrder) => {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {
                columnOrder
            })
        }, this.saveTableStateToLocalStorage);
    }

    onBatchColumnResize = (batchColumnInfo, { reservedViewportWidth }) => {
        const colsMap = batchColumnInfo.reduce((acc, colInfo) => {
            const { column, width, flex } = colInfo
            acc[column.name] = { width, flex }
            return acc
        }, {})

        const columns = this.state.allapot.columns.map(c => {
            return Object.assign({}, c, colsMap[c.name])
        })

        this.setState({
            allapot: Object.assign({}, this.state.allapot, {
                columns,
                reservedViewportWidth
            })
        }, this.saveTableStateToLocalStorage)
    }

    onColumnVisibleChange = ({ column, visible }) => {
        const columns = this.state.allapot.columns.map((e) => {
            if (e.name == column.name) e.visible = visible
            return e
        })
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {
                columns
            })
        }, this.saveTableStateToLocalStorage);
    }

    backupGridState = () => {
        this.saveTableStateToLocalStorage()
    }

    restoreGridState = () => {
        this.reloadTableStateFromLocalStorage()
    }

    saveTableStateToLocalStorage = () => {
        localStorage.setItem(
            this.tablaKontextusNev,
            JSON.stringify(this.state.allapot))
    }

    reloadTableStateFromLocalStorage = () => {
        const backupedStateStr = localStorage.getItem(this.tablaKontextusNev);
        if (!backupedStateStr) return;
        let backupedState = JSON.parse(backupedStateStr)
        if(backupedState.verzio!=this.kezdoAllapot.verzio) return;
        // A visszatoltott column allapothoz visszaalitjuk a render metodusokat
        backupedState.columns = backupedState.columns.map(e => { if (e.renderelo) e.render = this.renderelo[e.renderelo]; return e })
        this.setState({ allapot: backupedState })
    }

    onEnableFilteringChange=(b)=> {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {enableFiltering:b})
        }, this.saveTableStateToLocalStorage);
    }

    onChangeFilterEnabled = (e) => {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {enableFiltering:e.target.checked})
        }, this.saveTableStateToLocalStorage);        
    }

    onGlobalFilterTextChanged = (e) => {
        this.setState({
            globalFilterText:e.target.value,
            selected: {}
        })
    }

    onLimitChanged=(n)=> {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {limit:n})
        }, this.saveTableStateToLocalStorage);
    }

    onFilterValueChanged=(e)=> {
        // Todo: tovabb kell fejleszteni: https://reactdatagrid.io/docs/api-reference#props-handle
        // Hogy csak azokat a kivalasztasokat toroljuk amik nem latszodnak
        this.setState({ selected: {} })    
    }

    onHandleGridRef=(r) => {
        this.setState({ gridRef: r })   
    }

    onSaveOrUpdateAdatlap=(newEntitas)=> {
        const modositas=[...this.state.dataSource];
        const newEntityNotOnTheList=!modositas.find(oldentitas=>oldentitas.id==newEntitas.id)
        if(newEntityNotOnTheList) {
            modositas[modositas.length]=newEntitas
        } else {
            modositas.forEach((oldentitas,index)=>{if(oldentitas.id==newEntitas.id) modositas[index]=newEntitas})
        }
        this.setState({dataSource:modositas})
    }

    generateItems= async()=> {
        const mennyiseg=this.generateAmountRef.current.value
        if(isNaN(mennyiseg))return;
        const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employee/generate/"+mennyiseg)
        const json = resp.data
        const modositas=[...this.state.dataSource,...json]
        this.setState({ dataSource: modositas })
    }
    
    render() {
        const rowHeight=40
        const calculatedHeight = 
            (this.state.dataSource.length>this.state.allapot.limit?
                this.state.allapot.limit:
                this.state.dataSource.length) * rowHeight
             + 100 + (this.state.allapot.enableFiltering?40:0)
        const gridStyle = { minHeight: calculatedHeight, zIndex:1 }
        const { selected } = this.state
        let datasource=this.state.dataSource
        const globalFilterText=this.state.globalFilterText

        if(globalFilterText)
            datasource=datasource.filter(obj => 
                Object.values(obj).some(val => 
                    val?.toString().toLowerCase().includes(globalFilterText.toLowerCase())));
   

        return (
            <>

                <div style={{display:this.state.adatlap.show?"none":"block"}}>

                    <h2 className="mb-4">Employees</h2>

                    <div
                        className="p-3"
                        style={{
                            border: "1px solid lightgray",
                            borderRadius: "20px",
                            backgroundColor:"#fafafa",
                            
                        }}
                    >


                        {this.columnFilterSupported &&
                            <Form.Check
                                className="m-2 ms-0"
                                style={{ display: "inline-block" }}
                                type="switch"
                                id="custom-switch"
                                label="filtering"
                                checked={this.state.allapot.enableFiltering}
                                onChange={this.onChangeFilterEnabled}
                            />
                        }

                        {this.state.allapot.enableFiltering &&
                        <Form.Control
                            className="mb-2 ms-0 me-2"
                            type="text"
                            placeholder="Global filter"
                            style={{ display: "inline-block", width: "200px" }}
                            size="sm"
                            value={this.state.globalFilterText}
                            onChange={this.onGlobalFilterTextChanged}
                        />}



                        <ReactDataGrid
                            handle={this.onHandleGridRef}


                            selected={this.state.selected}
                            idProperty="id"

                            dataSource={datasource}
                            style={gridStyle}
                            enableSelection
                            multiSelect
                            defaultSortInfo={[]}
                            checkboxColumn
                            pagination="local"
                            onSelectionChange={this.onSelectionChange}

                            columns={this.state.allapot.columns}
                            reservedViewportWidth={this.state.allapot.reservedViewportWidth}

                            columnOrder={this.state.allapot.columnOrder}
                            onColumnOrderChange={this.onColumnOrderChange}
                            onBatchColumnResize={this.onBatchColumnResize}
                            onColumnVisibleChange={this.onColumnVisibleChange}

                            filterTypes={this.filterTypes}
                            defaultFilterValue={this.filterValue}

                            onEnableFilteringChange={this.onEnableFilteringChange}
                            enableFiltering={this.columnFilterSupported && this.state.allapot.enableFiltering}

                            onFilterValueChange={this.onFilterValueChanged}

                            limit={this.state.allapot.limit}
                            onLimitChange={this.onLimitChanged}
                        />

                        <Row className="ps-1 pt-2">
                            <Col className="col-auto align-self-center p-2">
                                <Button onClick={this.handleAdatlapShow} variant="primary">
                                    New item
                                </Button>
                            </Col>

                            <Col className="col-auto align-self-center p-2">
                                <Button
                                    style={{zIndex:0}}
                                    onClick={() => { this.confirmDeletionDialogShow(true) }}
                                    disabled={Object.keys(selected).length < 1}
                                    variant="primary">
                                    {Object.keys(selected).length == 1 ? "Delete item" : "Delete items"}
                                </Button>
                            </Col>      
                        </Row>

                        <Modal   show={this.state.confirmDeletionDialog.show} onHide={() => { this.confirmDeletionDialogShow(false) }}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm deletion</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Do you confirm deletions? {this.state.confirmDeletionDialog.entitasIDk?.length} pcs</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => { this.confirmDeletionDialogShow(false) }}>
                                    NO
                                </Button>
                                <Button variant="primary" onClick={this.deletes}>
                                    YES
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>


                { 
                    (false || this.state.adatlap.show) &&

                    <div style={{maxWidth:992}}>
                        <EmployeeAdatlap 
                            id={this.state.adatlap.entitasid} 
                            key={this.state.adatlap.entitasid} 
                            onSaveOrUpdate={this.onSaveOrUpdateAdatlap}
                            button=
                                {<Button variant="primary" onClick={this.handleAdatlapClose} >
                                    Back
                                </Button>}
                        />
                    </div>
                }

            </>


        )


    }
}
