import React from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { detect } from "detect-browser";
import UserAdatlap from "./UserAdatlap"
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import axios from 'axios';
import * as UtilsReactDataGridIO from "../UtilsReactDataGridIO"

export default class UsersTabla extends React.Component {
    constructor(props) {
        super(props);
        this.generateAmountRef = React.createRef();

        this.tablaKontextusNev = props.ktxname
        this.renderelo = 
            UtilsReactDataGridIO.createGeneralRenders({
                handleDatasheetClickEvent:this.onAkcioAdatlapClicked,
                handleDeleteClickEvent:this.onAkcioTorlesClicked})            

        const tabledef=[
            {prop:"id",         renderelo:"multiPropStringRender",     szuro:"nested"},
            {prop:"username",   renderelo:"multiPropStringRender",     szuro:"nested"},

            {prop:"email",      label:"e-mail",     renderelo:"multiPropStringRender",     szuro:"nested"},
            {prop:"firstName",  label:"first name", renderelo:"multiPropStringRender",     szuro:"nested"},
            {prop:"lastName",   label:"last name",  renderelo:"multiPropStringRender",     szuro:"nested"},
            {prop:"groups",     label:"groups",      renderelo:"multiPropStringRender",     szuro:"nested"},

            {prop:"enabled",      label:"enabled",      renderelo:"multiPropBooleanRender", szuro:"bool"},
            {prop:"emailVerified",label:"e-mail verified",renderelo:"multiPropBooleanRender", szuro:"bool"},

            {prop:"edit",   label:"edit",   renderelo:"datasheetRender"},
        ]


        this.kezdoAllapot = {
            verzio: 1, // Ha valtozik a kezdoallapot(konfiguracio) akkor noveld ezt meg, hogy ne a regi "rosszat toltse be"
            columns:UtilsReactDataGridIO.createColumnDefinifitions(tabledef,this.renderelo),
            reservedViewportWidth: 0,
            enableFiltering: true,
            limit: 10,
            backupverzio: 1,
        }

        this.kezdoAllapot.columnOrder = this.kezdoAllapot.columns.map(e => e.name || e.id)
        this.filterTypes = UtilsReactDataGridIO.filterTypes
        this.filterValue= UtilsReactDataGridIO.createFilterDefinifitions(tabledef)

        this.columnFilterSupported = true
        const browser = detect();

        if (browser && browser.os &&
            [
                "iPad Simulator",
                "iPhone Simulator",
                "iPod Simulator",
                "iPad",
                "Phone",
                "iPod",
                "IOS",
                "MAC"
            ].find(e => e.toLowerCase().includes(browser.os.toLowerCase()))
        ) {
            const majorVersionFind = browser.version.match(/(\d+)\.\d+\.\d+/)
            const majorVersionOK = majorVersionFind[1] && majorVersionFind[1] >= 16
            this.columnFilterSupported =
                browser.version.toLowerCase().includes("safari") && majorVersionOK
        }

        this.state = {
            allapot: this.kezdoAllapot,
            globalFilterText: "",
            selected: [],
            adatlap: { show: false, entitasid: null },
            confirmDeletionDialog: { show: false, entitasIDk: [] },
            dataSource: [],
            gridRef: null
        };
    }

    onAkcioAdatlapClicked = (id) => {
        this.setState({ adatlap: { show: true, entitasid: id } })
    }

    onAkcioTorlesClicked = (id) => {
        this.setState({ confirmDeletionDialog: { show: true, entitasIDk: [parseInt(id)] } })
    }

    onSelectionChange = (e) => {
        if (e.selected === true) {
            let selected = {}
            e.data.forEach((value, index) => {
                selected[value.id] = value;
            });
            this.setState({
                selected: selected
            })
        } else {
            this.setState({
                selected: e.selected
            })
        }
    }


    handleAdatlapShow = () => {
        this.setState({ adatlap: { show: true, entitasid: null } })
    }

    handleAdatlapClose = () => {
        this.setState({ adatlap: { show: false, entitasid: null } })
    }

    componentDidMount = () => {
        this.loadDatas()
        try {
            this.reloadTableStateFromLocalStorage()
        } catch (error) {
            this.setState({ allapot: this.kezdoAllapot })
        }
    }

    deletes = async () => {
        // TODO
        /*const IDs = this.state.confirmDeletionDialog.entitasIDk;

        const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employment-relationship", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify(IDs)
        })
        if (resp.status != 200) {
            console.log(await resp.data);
            alert("Error during deletion(s), please watch the console!")
            return;
        }
        const modositas = this.state.dataSource.filter(e => IDs.includes(e.id) ? false : true).map(e => e)
        this.setState({
            dataSource: modositas,
            selected: {},
            confirmDeletionDialog: { show: false, entitasok: [] }
        })*/
    }

    confirmDeletionDialogShow = (b) => {
        this.setState({ confirmDeletionDialog: { show: b, entitasIDk: b ? Object.keys(this.state.selected).map(e => parseInt(e)) : [] } })
    }


    loadDatas = async () => {
        const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/keycloak/users")
        console.log("DATAS");
        console.log(resp.data)
        this.setState({ dataSource: resp.data })
    }


    onColumnOrderChange = (columnOrder) => {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {
                columnOrder
            })
        }, this.saveTableStateToLocalStorage);
    }

    onBatchColumnResize = (batchColumnInfo, { reservedViewportWidth }) => {
        const colsMap = batchColumnInfo.reduce((acc, colInfo) => {
            const { column, width, flex } = colInfo
            acc[column.name] = { width, flex }
            return acc
        }, {})

        const columns = this.state.allapot.columns.map(c => {
            return Object.assign({}, c, colsMap[c.name])
        })

        this.setState({
            allapot: Object.assign({}, this.state.allapot, {
                columns,
                reservedViewportWidth
            })
        }, this.saveTableStateToLocalStorage)
    }

    onColumnVisibleChange = ({ column, visible }) => {
        const columns = this.state.allapot.columns.map((e) => {
            if (e.name == column.name) e.visible = visible
            return e
        })
        this.setState({
            allapot: Object.assign({}, this.state.allapot, {
                columns
            })
        }, this.saveTableStateToLocalStorage);
    }

    backupGridState = () => {
        this.saveTableStateToLocalStorage()
    }

    restoreGridState = () => {
        this.reloadTableStateFromLocalStorage()
    }

    saveTableStateToLocalStorage = () => {
        localStorage.setItem(
            this.tablaKontextusNev,
            JSON.stringify(this.state.allapot))
    }

    reloadTableStateFromLocalStorage = () => {
        const backupedStateStr = localStorage.getItem(this.tablaKontextusNev);
        if (!backupedStateStr) return;
        let backupedState = JSON.parse(backupedStateStr)
        if (backupedState.verzio != this.kezdoAllapot.verzio) return;
        // A visszatoltott column allapothoz visszaalitjuk a render metodusokat
        backupedState.columns = backupedState.columns.map(e => { if (e.renderelo) e.render = this.renderelo[e.renderelo]; return e })
        this.setState({ allapot: backupedState })
    }

    onEnableFilteringChange = (b) => {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, { enableFiltering: b })
        }, this.saveTableStateToLocalStorage);
    }

    onChangeFilterEnabled = (e) => {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, { enableFiltering: e.target.checked })
        }, this.saveTableStateToLocalStorage);
    }

    onGlobalFilterTextChanged = (e) => {
        this.setState({
            globalFilterText: e.target.value,
            selected: {}
        })
    }

    onLimitChanged = (n) => {
        this.setState({
            allapot: Object.assign({}, this.state.allapot, { limit: n })
        }, this.saveTableStateToLocalStorage);
    }

    onFilterValueChanged = (e) => {
        // Todo: tovabb kell fejleszteni: https://reactdatagrid.io/docs/api-reference#props-handle
        // Hogy csak azokat a kivalasztasokat toroljuk amik nem latszodnak
        this.setState({ selected: {} })
    }

    onHandleGridRef = (r) => {
        this.setState({ gridRef: r })
    }

    onSaveOrUpdateAdatlap = (newEntitas) => {
        const modositas = [...this.state.dataSource];
        const newEntityNotOnTheList = !modositas.find(oldentitas => oldentitas.id == newEntitas.id)
        if (newEntityNotOnTheList) {
            modositas[modositas.length] = newEntitas
        } else {
            modositas.forEach((oldentitas, index) => { if (oldentitas.id == newEntitas.id) modositas[index] = newEntitas })
        }
        this.setState({ dataSource: modositas })
    }

    render() {
        const rowHeight = 40
        const calculatedHeight =
            (this.state.dataSource.length > this.state.allapot.limit ?
                this.state.allapot.limit :
                this.state.dataSource.length) * rowHeight
            + 100 + (this.state.allapot.enableFiltering ? 40 : 0)
        const gridStyle = { minHeight: calculatedHeight, zIndex: 1 }
        const { selected } = this.state
        let datasource = this.state.dataSource
        const globalFilterText = this.state.globalFilterText

        if (globalFilterText)
            datasource = datasource.filter(obj =>
                Object.values(obj).some(val =>
                    val?.toString().toLowerCase().includes(globalFilterText.toLowerCase())));



        return (
            <>


                <Form.Control
                    className="mb-2 ms-0 me-2"
                    type="text"
                    placeholder="Global filter"
                    style={{ display: "inline-block", width: "200px" }}
                    size="sm"
                    value={this.state.globalFilterText}
                    onChange={this.onGlobalFilterTextChanged}
                />

                {this.columnFilterSupported &&
                    <Form.Check
                        className="m-2"
                        style={{ display: "inline-block" }}
                        type="switch"
                        id="custom-switch"
                        label="Enable column filtering"
                        checked={this.state.allapot.enableFiltering}
                        onChange={this.onChangeFilterEnabled}
                    />
                }



                <ReactDataGrid
                    handle={this.onHandleGridRef}


                    selected={this.state.selected}
                    idProperty="id"

                    dataSource={datasource}
                    style={gridStyle}
                    enableSelection
                    multiSelect
                    defaultSortInfo={[]}
                    checkboxColumn
                    pagination="local"
                    onSelectionChange={this.onSelectionChange}

                    columns={this.state.allapot.columns}
                    reservedViewportWidth={this.state.allapot.reservedViewportWidth}

                    columnOrder={this.state.allapot.columnOrder}
                    onColumnOrderChange={this.onColumnOrderChange}
                    onBatchColumnResize={this.onBatchColumnResize}
                    onColumnVisibleChange={this.onColumnVisibleChange}

                    filterTypes={this.filterTypes}
                    defaultFilterValue={this.filterValue}

                    onEnableFilteringChange={this.onEnableFilteringChange}
                    enableFiltering={this.columnFilterSupported && this.state.allapot.enableFiltering}

                    onFilterValueChange={this.onFilterValueChanged}

                    limit={this.state.allapot.limit}
                    onLimitChange={this.onLimitChanged}
                />

                <Modal dialogClassName="my-modal" show={this.state.adatlap.show} onHide={this.handleAdatlapClose}>
                    <Modal.Body>
                        <UserAdatlap id={this.state.adatlap.entitasid} onSaveOrUpdate={this.onSaveOrUpdateAdatlap} />
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.confirmDeletionDialog.show} onHide={() => { this.confirmDeletionDialogShow(false) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you confirm deletions? {this.state.confirmDeletionDialog.entitasIDk?.length} pcs</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.confirmDeletionDialogShow(false) }}>
                            NO
                        </Button>
                        <Button variant="primary" onClick={this.deletes}>
                            YES
                        </Button>
                    </Modal.Footer>
                </Modal>



            </>


        )


    }
}
