import React from 'react'
import ReactDOM from 'react-dom/client'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import { nanoid } from 'nanoid'
import _ from 'lodash';
import axios from 'axios';
import UserAdd from "../assets/user-add-svgrepo-com.svg"
import UserLinked from "../assets/link-h-svgrepo-com.svg"
import CircularProgress from '@mui/material/CircularProgress';
import TextField1 from '../comps/datasheet/mui/TextField1';
import TextArea1 from '../comps/datasheet/mui/TextArea1';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmployeeSelection from '../comps/datasheet/mui/EmployeeSelection';
import Popover from '../comps/Popover';
import TextFieldWithUnit1 from '../comps/datasheet/mui/TextFieldWithUnit1';
import EmploymentRelationshipAdatlap from '../EmploymentRelationship/EmploymentRelationshipAdatlap';
import EmploymentRelationshipTabla from '../EmploymentRelationship/EmploymentRelationshipTabla';

export default class EmployeeAdatlap extends React.Component {
    constructor(props) {
        super(props);
        this.id = props.id
        this.additionalButton=props.button
        this.onSaveOrUpdateListener = props.onSaveOrUpdate
        this.entitas = this.props.entitas
        this.state = {
            entitas: {
                id: null
            },
            saved: this.entitas?.id ? true : false,
            userlinkprogress: false,
            checkForAccordionExpaneded: false,
            employmentRelationships:[]
        };
    }

    loadEntitas = async (id) => {
        const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employee/" + id)
        const eResp = resp.data
        this.setState({
            entitas: eResp,
            checkForAccordionExpaneded: 
                ["checkForFirstName","checkForLastName","checkForAddress"]
                    .map(s=>eResp?.[s])
                    .filter(e=>e!=null && e.trim()!="")
                    .length>0,
            saved: eResp.id?true:false
        },this.loadEmploymentRelationships)
    }

    /*loadEmploymentRelationships = () => {
        if(this.loadEmploymentRelationshipsTimer!=null)
            clearTimeout(this.loadEmploymentRelationshipsTimer);
        this.loadEmploymentRelationshipsTimer=setTimeout(() => {
            (async()=>{
                console.log("loadEmploymentRelationships: valóban!");
                let employmentRelationships=[]
                if(this.state.entitas?.id) {
                    const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employment-relationship/all/" + this.state.entitas?.id)
                    employmentRelationships = resp.data
                }
                this.setState({
                    employmentRelationships:employmentRelationships
                })
            })()
        },5000)
    }*/ 
    

    loadEmploymentRelationships = async () => {
        let employmentRelationships=[]
        if(this.state.entitas?.id) {
            const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employees/"+this.state.entitas.id+"/employment-relationships")
            employmentRelationships = resp.data
            console.log("employmentRelationships");
            console.log(employmentRelationships);
        }
        this.setState({
            employmentRelationships:employmentRelationships
        })
    }     

    componentDidMount = () => {
        if (!this.entitas && this.id) this.loadEntitas(this.id)
    }

    saveOrUpdate = async () => {
        console.log(this.state.entitas);
        try {
            const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employee", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify(this.state.entitas)
            })
            if (resp.status != 200) {
                if(resp.data?.fullMessage)
                    console.log(resp.data.fullMessage);
                alert(resp.data.message)
            } else {
                const employee=resp.data
                this.setState(
                    {
                        entitas: resp.data,
                        saved: resp.data.id?true:false
                    },()=>{
                        if (this.onSaveOrUpdateListener) 
                            this.onSaveOrUpdateListener(resp.data)
                    })
            }
        } catch(error) {
            if(error.response?.data?.fullMessage)
                console.log(error.response.data.fullMessage);
            alert(error.response.data.message) 
        }
    }



    useradd = async () => {
        this.setState({ userlinkprogress: true })
        //console.log(this.state.teammate.id);
        console.log(this.state.entitas.id);
        const registration = {
            employeeID: this.state.entitas.id,
            email: this.state.entitas.email,
            firstName: this.state.entitas.firstName,
            lastName: this.state.entitas.lastName
        }
        console.log(registration);
        try {
            const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/keycloak/user-registration-for-employee", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify(registration)
            })
            const data = resp.data
            this.setState({ userlinkprogress: false })
            if (resp.status != 200) {
                alert(data.message)
                console.log(data.fullMessage);
            } else {
                console.log("Sikeres link");
                console.log(resp.data);
                this.setState(
                    {
                        entitas: resp.data,
                        saved: resp.data.id?true:false
                    },()=>{
                        if (this.onSaveOrUpdateListener) 
                            this.onSaveOrUpdateListener(data)
                    })
            }
        } catch (error) {
            this.setState({ userlinkprogress: false })
            if (error?.response?.data?.message)
                alert(error.response.data.message);
            else
                alert(error.response.data);
            if (error?.response?.data?.fullMessage) {
                alert("More info is on console.")
                console.log(error.response.data.fullMessage);
            }
        }
    }


    render() {

        const isKeycloakUserAssigned = this.state.entitas?.kcuserid ? true : false

        const createTextField = ({p,l,d,...rest}) => {
            return (
                <TextField1
                    label={l}
                    disabled={d?d:false}
                    value={this.state.entitas?.[p] || ""}
                    onChange={e => {
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [p]: e.target.value },
                                saved: false
                            })
                        );
                    }}
                />)
        }

        const createTextArea = ({p,l,d,...rest}) => {
            return (
                <TextArea1
                    label={l}
                    value={this.state.entitas?.[p] || ""}
                    onChange={e => {
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [p]: e.target.value },
                                saved: false
                            })
                        );
                    }}
                />)
        }        

        const createTextFieldWithUnit = ({p,l,d,unit,onChangeHandler,...rest}) => {
            return (
                <TextFieldWithUnit1
                    label={l}
                    unit={unit}
                    disabled={d?d:false}
                    value={this.state.entitas?.[p] || ""}
                    onChange={onChangeHandler?onChangeHandler:e => {
                        console.log("Megvaltozott")
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [p]: e.target.value },
                                saved: false
                            })
                        );
                    }}
                    {...rest}
                />)
        } 

        /*
        ███    ███ ██    ██ ███    ██ ██   ██  █████  ██    ██ ██ ███████ ███████  ██████  ███    ██ ██    ██  ██████  ██   ██
        ████  ████ ██    ██ ████   ██ ██  ██  ██   ██ ██    ██ ██ ██         ███  ██    ██ ████   ██  ██  ██  ██    ██ ██  ██
        ██ ████ ██ ██    ██ ██ ██  ██ █████   ███████ ██    ██ ██ ███████   ███   ██    ██ ██ ██  ██   ████   ██    ██ █████
        ██  ██  ██ ██    ██ ██  ██ ██ ██  ██  ██   ██  ██  ██  ██      ██  ███    ██    ██ ██  ██ ██    ██    ██    ██ ██  ██
        ██      ██  ██████  ██   ████ ██   ██ ██   ██   ████   ██ ███████ ███████  ██████  ██   ████    ██     ██████  ██   ██


        ███████ ██    ██  ██████   ██████  ██    ██ ███████ ███    ██ ██    ██ ███████
        ██      ██    ██ ██       ██       ██    ██ ██      ████   ██  ██  ██  ██
        █████   ██    ██ ██   ███ ██   ███ ██    ██ █████   ██ ██  ██   ████   █████
        ██      ██    ██ ██    ██ ██    ██  ██  ██  ██      ██  ██ ██    ██    ██
        ██       ██████   ██████   ██████    ████   ███████ ██   ████    ██    ███████
        */

        let showThisEmploymentRelationshipID=null
        if(this.state.employmentRelationships!=null) {
            const munkaviszonyokMennyisege=
                this.state.employmentRelationships.length
            const aktualisMunkaviszonyokMennyisege=
                this.state.employmentRelationships.filter(e=>e.open).length
            if(munkaviszonyokMennyisege==1) {
                showThisEmploymentRelationshipID=
                    this.state.employmentRelationships[0].id
            } else if(aktualisMunkaviszonyokMennyisege==1) {
                showThisEmploymentRelationshipID=
                    this.state.employmentRelationships.filter(e=>e.open)[0].id
            }
        }



        return (
            <>
                <h2 className="mb-4 pb-2">
                    <Popover popovertext={this.state.entitas.id ? "ID: "+this.state.entitas.id : " new item"}>
                        Employee 
                    </Popover>
                </h2>
                <div style={{ border: "solid 1px lightgray", padding: "20px", borderRadius: "20px", backgroundColor:"#fafafa" }}>
                    
                    <form>
                            {
                                [
                                    [
                                        { p: "firstName",               l: "First name",        c: "col-12 col-sm-5" },
                                        { p: "lastName",                l: "Last name",         c: "col-12 col-sm-5" },
                                        { p: "socialSecurityNumber",    l: "SSN",               c: "col-12 col-sm-2" }
                                    ],
                                    [
                                        { p: "email",       l: "E-mail",            c: "col-12 col-sm-6", d:isKeycloakUserAssigned},
                                        { p: "kcuserid",     l: "Keycloak user id", c: "col-12 col-sm-6", d: true },
                                    ],
                                    [
                                        { p: "address",     l: "Address",           c: "col-12 col-sm-6" },
                                        { p: "apartment",   l: "Apt",               c: "col-12 col-sm-2" },
                                        { p: "city",        l: "City",              c: "col-12 col-sm-4" }
                                    ],
                                    [    
                                        { p: "state",       l: "State",             c: "col-12 col-sm-4" },
                                        { p: "zipCode",     l: "Zip code",          c: "col-12 col-sm-4" },
                                        { p: "phone",       l: "Phone",             c: "col-12 col-sm-4" }
                                    ]
                                ].map((blokk,i)=>
                                    <Row key={i} style={{borderBottom:"solid 1px #e1e1e1"}} className="mb-2 pb-2">
                                        {
                                            blokk.map(({ p, l, c, d, t }, i) => 
                                                <Col className={c+" p-2 pb-3 pb-sm-2"} key={i}>
                                                    {createTextField({p,l,d})}
                                                </Col>)
                                        }
                                    </Row>
                                )
                            }
                            <Row>
                                <Col className="col-12 p-2">
                                    {createTextArea({p:"comment",l:"comment"})}
                                </Col>
                            </Row>


                        <div style={{marginTop:"15px",marginBottom:"15px"}}>
                            <Accordion 
                                expanded={this.state.checkForAccordionExpaneded}
                                onChange={(event, isExpanded) =>{this.setState({checkForAccordionExpaneded:isExpanded})}}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>alternative check-for data</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                        <Row>
                                            {[    
                                                { p: "checkForFirstName",       l: "Check-for first name",  c: "col-12 col-sm-4" },
                                                { p: "checkForLastName",        l: "Check-for last name",   c: "col-12 col-sm-4" },
                                                { p: "checkForAddress",         l: "Check-for address",     c: "col-12 col-sm-4" }
                                            ].map(({ p, l, c, d, t }, i) => 
                                                <Col className={c+" p-2 pb-3 pb-sm-2"} key={i}>
                                                    {createTextField({p,l,d})}
                                                </Col>
                                            )}
                                        </Row>
                                </AccordionDetails>
                            </Accordion>
                        </div>


                        {/*
                        ██████  ███████ ███████ ███████ ██████  ███████ ███    ██  ██████ ███████
                        ██   ██ ██      ██      ██      ██   ██ ██      ████   ██ ██      ██
                        ██████  █████   █████   █████   ██████  █████   ██ ██  ██ ██      █████
                        ██   ██ ██      ██      ██      ██   ██ ██      ██  ██ ██ ██      ██
                        ██   ██ ███████ ██      ███████ ██   ██ ███████ ██   ████  ██████ ███████


                        ██████  ███████ ██████  ███████  ██████  ███    ██
                        ██   ██ ██      ██   ██ ██      ██    ██ ████   ██
                        ██████  █████   ██████  ███████ ██    ██ ██ ██  ██
                        ██      ██      ██   ██      ██ ██    ██ ██  ██ ██
                        ██      ███████ ██   ██ ███████  ██████  ██   ████


                        ██    ██  █████  ██       █████  ███████ ███████ ████████  ██████
                        ██    ██ ██   ██ ██      ██   ██ ██         ███     ██    ██    ██
                        ██    ██ ███████ ██      ███████ ███████   ███      ██    ██    ██
                        ██  ██  ██   ██ ██      ██   ██      ██  ███       ██    ██    ██
                        ████   ██   ██ ███████ ██   ██ ███████ ███████    ██     ██████
                        */}

                        <Row>
                            <Col className="col-12 col-sm-9 p-2 pb-3 pb-sm-2">
                                <EmployeeSelection
                                    value={this.state.entitas?.referencePerson || null}
                                    label="Reference person"
                                    onChangeHandler={
                                        (event,newReferencePerson)=>{
                                            this.setState(
                                                prevstate => ({
                                                    entitas: { 
                                                        ...prevstate.entitas, 
                                                        referencePerson: newReferencePerson,
                                                    },
                                                    saved: false
                                                })
                                            );
                                        }
                                    }
                                />
                            </Col>

                        {/*
                        ██████  ███████ ███████ ███████ ██████  ███████ ███    ██  ██████ ███████
                        ██   ██ ██      ██      ██      ██   ██ ██      ████   ██ ██      ██
                        ██████  █████   █████   █████   ██████  █████   ██ ██  ██ ██      █████
                        ██   ██ ██      ██      ██      ██   ██ ██      ██  ██ ██ ██      ██
                        ██   ██ ███████ ██      ███████ ██   ██ ███████ ██   ████  ██████ ███████


                        ██████  ███████ ██████  ███████  ██████  ███    ██
                        ██   ██ ██      ██   ██ ██      ██    ██ ████   ██
                        ██████  █████   ██████  ███████ ██    ██ ██ ██  ██
                        ██      ██      ██   ██      ██ ██    ██ ██  ██ ██
                        ██      ███████ ██   ██ ███████  ██████  ██   ████


                        ██████  ██████  ███    ███ ███    ███ ██ ███████ ███████ ██  ██████  ███    ██
                        ██      ██    ██ ████  ████ ████  ████ ██ ██      ██      ██ ██    ██ ████   ██
                        ██      ██    ██ ██ ████ ██ ██ ████ ██ ██ ███████ ███████ ██ ██    ██ ██ ██  ██
                        ██      ██    ██ ██  ██  ██ ██  ██  ██ ██      ██      ██ ██ ██    ██ ██  ██ ██
                        ██████  ██████  ██      ██ ██      ██ ██ ███████ ███████ ██  ██████  ██   ████
                        */}

                            <Col className="col-12 col-sm-3 p-2 pb-3 pb-sm-2">
                                {createTextFieldWithUnit({
                                    p:"referencePersonCommission",
                                    l:"reference person commission",
                                    unit:"$",
                                    unitPosition:"start"
                                })}
                            </Col>   
                        </Row>                    



                    </form>

                    <p>
                        {this.state.entitas?.kcuserid &&
                            <img
                                onClick={() => {
                                    navigator.clipboard.writeText(this.state.entitas.kcuserid);
                                    alert("Keycloak user ID is copied to the clipboard!")
                                }}
                                src={UserLinked}
                                style={{ width: "25px" }}
                            />
                        }

                        {
                            !this.state.entitas?.kcuserid && this.state.saved && (
                                this.state.userlinkprogress ?
                                    <CircularProgress size={30} />
                                    :
                                    <a onClick={e => { if (false && !confirm("Confirm?")) return; this.useradd() }}>
                                        <img
                                            src={UserAdd}
                                            style={{ width: "25px" }}
                                            title="Link keycloack user"
                                            alt="Link keycloack user"
                                        />
                                    </a>
                            )
                        }
                    </p>

                    <Row className="justify-content-between">
                            {
                                this.additionalButton &&
                                <Col>
                                        {React.cloneElement(this.additionalButton,{ className: "m-2 ms-0 mt-3" })}
                                </Col>
                            }
                            <Col className="col-auto">
                                <Button
                                    onClick={this.saveOrUpdate}
                                    className="m-2 ms-0 mt-3"
                                    variant={this.state.saved?"primary":"danger"}
                                >
                                    {this.state.entitas?.id ? "Save item" : "Create new item"}
                                </Button>
                            </Col>
                        </Row>


                Open: {this.state.employmentRelationships.filter(e=>e.open).length} pcs,
                archive: {this.state.employmentRelationships.filter(e=>!e.open).length} pcs 

                {
                    this.state.entitas?.id &&

                    <div className="mt-3">
                        <EmploymentRelationshipTabla 
                            employeeID={this.state.entitas?.id}
                            ktxname="employment-relationship.lista" 
                            onChangeHandler={
                                this.loadEmploymentRelationships
                            }
                            showAdatlap={showThisEmploymentRelationshipID!=null || this.state.employmentRelationships.length==0}
                            showEntitasOnAdatlap={showThisEmploymentRelationshipID}
                            key={
                                "employment-relationship.lista-id-"+this.state.entitas.id+
                                "-version-"+this.state.entitas.version+
                                "-"+this.state.employmentRelationships?.length} />
                    </div>
                }       

                </div>
            </>
        );
    }
}