import React from 'react'
import ReactDOM from 'react-dom/client'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import _ from 'lodash';
import axios from 'axios';
import TextField1 from '../comps/datasheet/mui/TextField1';
import TextFieldWithUnit1 from '../comps/datasheet/mui/TextFieldWithUnit1';
import TextArea1 from '../comps/datasheet/mui/TextArea1';
import dayjs, { Dayjs } from 'dayjs';
import DatePicker1 from '../comps/datasheet/mui/DatePicker1';
import DateTimePicker1 from '../comps/datasheet/mui/DateTimePicker1';
import EmployeeSelection from '../comps/datasheet/mui/EmployeeSelection';
import ClientSelection from '../comps/datasheet/mui/ClientSelection';
import ClientPositionSelection from '../comps/datasheet/mui/ClientPositionSelection';
import Popover from '../comps/Popover';
import * as UtilsVSM from "../UtilsVSM"
import EmploymentRelationshipSelection from '../comps/datasheet/mui/EmploymentRelationshipSelection';
import TimezoneSelect from 'react-timezone-select'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch'

export default class WorkAdatlap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.id = props.id
        this.employeeID=props.employeeID
        this.additionalButton=props.button
        this.onSaveOrUpdateListener = props.onSaveOrUpdate
        this.entitas = this.props.entitas
        this.state = {
            entitas: {
                id: null
            },
            saved: this.entitas?.id ? true : false
        };        
    }


    /*
    ██       ██████   █████  ██████  ███████ ███    ██ ████████ ██ ████████  █████  ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ████   ██    ██    ██    ██    ██   ██ ██
    ██      ██    ██ ███████ ██   ██ █████   ██ ██  ██    ██    ██    ██    ███████ ███████
    ██      ██    ██ ██   ██ ██   ██ ██      ██  ██ ██    ██    ██    ██    ██   ██      ██
    ███████  ██████  ██   ██ ██████  ███████ ██   ████    ██    ██    ██    ██   ██ ███████
    */

    loadEntitas = async (id) => {
        const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/work/" + id)
        const eResp = resp.data
        this.setState({
            entitas: eResp,
            saved: eResp.id?true:false
        })
    }

    /*
     ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████ ██████  ██ ██████  ███    ███  ██████  ██    ██ ███    ██ ████████
    ██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██    ██   ██ ██ ██   ██ ████  ████ ██    ██ ██    ██ ████   ██    ██
    ██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██    ██   ██ ██ ██   ██ ██ ████ ██ ██    ██ ██    ██ ██ ██  ██    ██
    ██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██    ██   ██ ██ ██   ██ ██  ██  ██ ██    ██ ██    ██ ██  ██ ██    ██
     ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██    ██████  ██ ██████  ██      ██  ██████   ██████  ██   ████    ██
    */

    componentDidMount = () => {
        if (!this.entitas && this.id) this.loadEntitas(this.id)
        if (!this.entitas && !this.id) {
            (async (id) => {
                const resp = await axios.get(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/employee/" + id)
                const data = resp.data
                this.setState(
                    prevstate => ({
                        entitas: { ...prevstate.entitas, employee: data },
                    })
                );                
            }) (this.employeeID)
        } 
    }

    /*
    ███████  █████  ██    ██ ███████  ██████  ██████  ██    ██ ██████  ██████   █████  ████████ ███████
    ██      ██   ██ ██    ██ ██      ██    ██ ██   ██ ██    ██ ██   ██ ██   ██ ██   ██    ██    ██
    ███████ ███████ ██    ██ █████   ██    ██ ██████  ██    ██ ██████  ██   ██ ███████    ██    █████
         ██ ██   ██  ██  ██  ██      ██    ██ ██   ██ ██    ██ ██      ██   ██ ██   ██    ██    ██
    ███████ ██   ██   ████   ███████  ██████  ██   ██  ██████  ██      ██████  ██   ██    ██    ███████
    */

    saveOrUpdate = async () => {
        console.log(this.state.entitas);
        try {
            const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/work", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify(this.state.entitas)
            })
            if (resp.status != 200) {
                if(resp.data?.fullMessage)
                    console.log(resp.data.fullMessage);
                alert(resp.data.message)
            } else {
                const Work=resp.data
                this.setState(
                    {
                        entitas: resp.data,
                        saved: resp.data.id?true:false
                    },()=>{
                        if (this.onSaveOrUpdateListener) 
                            this.onSaveOrUpdateListener(resp.data)
                    })
            }
        } catch(error) {
            if(error.response?.data?.fullMessage)
                console.log(error.response.data.fullMessage);
            alert(error.response.data.message) 
        }
    }
    



    /*
    ██████  ███████ ███    ██ ██████  ███████ ██████
    ██   ██ ██      ████   ██ ██   ██ ██      ██   ██
    ██████  █████   ██ ██  ██ ██   ██ █████   ██████
    ██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██
    ██   ██ ███████ ██   ████ ██████  ███████ ██   ██
    */

    render() {

        /*
        ██    ██ ███████ ███████ ███████ ██████  ██       ██████  ██   ██     ███████ ███████  ██████  ███████ ██████
        ██    ██ ██         ███  ██      ██   ██ ██      ██    ██ ██  ██      ██      ██      ██       ██      ██   ██
        ██    ██ █████     ███   █████   ██████  ██      ██    ██ █████       ███████ █████   ██   ███ █████   ██   ██
         ██  ██  ██       ███    ██      ██   ██ ██      ██    ██ ██  ██           ██ ██      ██    ██ ██      ██   ██
          ████   ███████ ███████ ███████ ██   ██ ███████  ██████  ██   ██     ███████ ███████  ██████  ███████ ██████


         ██████  ██    ██  █████  ██████  ████████  ██████       ██  █████
        ██        ██  ██  ██   ██ ██   ██    ██    ██    ██      ██ ██   ██
        ██   ███   ████   ███████ ██████     ██    ██    ██      ██ ███████
        ██    ██    ██    ██   ██ ██   ██    ██    ██    ██ ██   ██ ██   ██
         ██████     ██    ██   ██ ██   ██    ██     ██████   █████  ██   ██
        */

        const createTextField = ({p,l,d,onChangeHandler,...rest}) => {
            return (
                <TextField1
                    label={l}
                    disabled={d?d:false}
                    value={this.state.entitas?.[p] || ""}
                    onChange={onChangeHandler?onChangeHandler:e => {
                        console.log("Megvaltozott")
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [p]: e.target.value },
                                saved: false
                            })
                        );
                    }}
                    {...rest}
                />)
        }

        const createTextFieldWithUnit = ({p,l,d,unit,onChangeHandler,...rest}) => {
            return (
                <TextFieldWithUnit1
                    label={l}
                    unit={unit}
                    disabled={d?d:false}
                    value={this.state.entitas?.[p] || ""}
                    onChange={onChangeHandler?onChangeHandler:e => {
                        console.log("Megvaltozott")
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [p]: e.target.value },
                                saved: false
                            })
                        );
                    }}
                    {...rest}
                />)
        }        

        const createTextArea = ({p,l,d,onChangeHandler,...rest}) => {
            return (
                <TextArea1
                    label={l}
                    value={this.state.entitas?.[p] || ""}
                    onChange={onChangeHandler?onChangeHandler:e => {
                        console.log("Megvaltozott")
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [p]: e.target.value },
                                saved: false
                            })
                        );
                    }}
                />)
        }     
        
        const createDatePicker= ({label,prop,onChangeHandler}) => {
            return(
                <DatePicker1
                    label={label}
                    value={this.state.entitas?.[prop]?dayjs(this.state.entitas?.[prop]):null}
                    onChange={onChangeHandler?onChangeHandler:v => {
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [prop]: v!=null?dayjs(v).format('YYYY-MM-DD'):null },
                                saved: false
                            })
                        );
                    }}
                />
            )
        }

        const createDateTimePicker= ({label,prop,onChangeHandler}) => {
            return(
                <DateTimePicker1
                    label={label}
                    value={this.state.entitas?.[prop]?dayjs(this.state.entitas?.[prop]):null}
                    onChange={onChangeHandler?onChangeHandler:v => {
                        this.setState(
                            prevstate => ({
                                entitas: { ...prevstate.entitas, [prop]: v!=null?dayjs(v).format('YYYY-MM-DD HH:mm:ss'):null },
                                saved: false
                            })
                        );
                    }}
                />
            )
        }        

        const createSwitch=({prop,label,onChangeHandler=null,color=null,disabled=false}) => {
            return(
                <FormControlLabel control={
                    <Switch
                        {...(color!=null && { color: color })}
                        {...(disabled==true && { disabled: true })}
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={this.state.entitas?.[prop]==null?false:this.state.entitas?.[prop]}
                        onChange={onChangeHandler?onChangeHandler:event => {
                            this.setState(
                                prevstate => ({
                                    entitas: { ...prevstate.entitas, [prop]: event.target.checked},
                                    saved: false
                                })
                            );
                        }}
                    />
                } label={label} />                                
            )
        }
      

        console.log("WORK");
        console.log(this.state.entitas);


        /*
        ██████  ███████ ████████ ██    ██ ██████  ███    ██
        ██   ██ ██         ██    ██    ██ ██   ██ ████   ██
        ██████  █████      ██    ██    ██ ██████  ██ ██  ██
        ██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██
        ██   ██ ███████    ██     ██████  ██   ██ ██   ████
        */

        return (
            <>
                <h2 className="mb-4 pb-2">
                
                { !this.state.entitas.id &&
                    "New work"
                }
                { this.state.entitas.id &&
                <Popover popovertext={"ID: "+this.state.entitas.id}>
                    Work 
                </Popover>
                }
                
                
                </h2>
                <div style={{ border: "solid 1px lightgray", padding: "20px", borderRadius: "20px", backgroundColor:"#fafafa", }}>
                    
                    <form>

                        <Row>
                            <Col className="col-12 p-2 pb-3 pb-sm-2">
                                <FormGroup>
                                    {createSwitch({prop:"closedByAccounting",label:"Closed by accounting",color:"secondary",disabled:true})}
                                </FormGroup>
                            </Col>
                        </Row>

                        {/*
                        ███    ███ ██    ██ ███    ██ ██   ██  █████  ████████  █████  ██████  ███████     ██    ██  █████  ██       █████  ███████ ███████ ████████  ██████
                        ████  ████ ██    ██ ████   ██ ██  ██  ██   ██    ██    ██   ██ ██   ██ ██          ██    ██ ██   ██ ██      ██   ██ ██         ███     ██    ██    ██
                        ██ ████ ██ ██    ██ ██ ██  ██ █████   ███████    ██    ███████ ██████  ███████     ██    ██ ███████ ██      ███████ ███████   ███      ██    ██    ██
                        ██  ██  ██ ██    ██ ██  ██ ██ ██  ██  ██   ██    ██    ██   ██ ██   ██      ██      ██  ██  ██   ██ ██      ██   ██      ██  ███       ██    ██    ██
                        ██      ██  ██████  ██   ████ ██   ██ ██   ██    ██    ██   ██ ██   ██ ███████       ████   ██   ██ ███████ ██   ██ ███████ ███████    ██     ██████
                        */}
                        <Row>
                            <Col className="col-12 p-2 pb-3 pb-sm-2">
                                <EmployeeSelection
                                    value={this.state.entitas.employee || null}
                                    onChangeHandler={
                                        (event,newEmployee)=>{
                                            this.setState(
                                                prevstate => ({
                                                    entitas: { 
                                                        ...prevstate.entitas, 
                                                        employee: newEmployee,
                                                        employmentRelationship: 
                                                            newEmployee?.id!=prevstate.entitas.employee?.id?
                                                                null:
                                                                prevstate.entitas.employmentRelationship
                                                    },
                                                    saved: false
                                                })
                                            );
                                        }
                                    }
                                    disabled={this.employeeID!=null}
                                />
                            </Col>
                        </Row>

                            { this.state.entitas?.employmentRelationship &&
                                <Row>
                                    <Col className="col-12 p-2 pb-3 pb-sm-2">
                                        Client:&nbsp; 
                                        <Popover popovertext={"ID: "+this.state.entitas?.employmentRelationship?.client?.id}>
                                            {this.state.entitas?.employmentRelationship?.client?.companyName}&nbsp; 
                                            ({[
                                                
                                                "address",
                                                "city",
                                                "state",
                                                "zipCode"
                                            ].filter(prop=>this.state.entitas?.employmentRelationship?.client?.[prop]!=null)
                                            .map(prop=>{
                                                return(this.state.entitas?.employmentRelationship?.client?.[prop])
                                            }).join(" - ")
                                            })
                                        </Popover>
                                    </Col>
                                </Row> 
                            }

                            { this.state.entitas?.employmentRelationship &&
                                <Row>
                                    <Col className="col-12 p-2 pb-3 pb-sm-2">
                                        Position:&nbsp; 
                                        <Popover popovertext={"ID: "+this.state.entitas?.employmentRelationship?.client?.id}>    
                                            {this.state.entitas?.employmentRelationship?.clientPosition?.name}&nbsp; 
                                            ({[
                                                {p:"billRate",l:"rate"},
                                                {p:"overtime",l:"overtime"}
                                            ].filter(e=>this.state.entitas?.employmentRelationship?.clientPosition?.[e.p]!=null)
                                            .map(e=>{
                                                return(e.l+": $"+this.state.entitas?.employmentRelationship?.clientPosition?.[e.p]+"/hour")
                                            }).join(" - ")
                                            })
                                        </Popover>
                                    </Col>
                                </Row>   
                            } 

                            {/*
                            ███    ███ ██    ██ ███    ██ ██   ██  █████  ██    ██ ██ ███████ ███████  ██████  ███    ██ ██    ██
                            ████  ████ ██    ██ ████   ██ ██  ██  ██   ██ ██    ██ ██ ██         ███  ██    ██ ████   ██  ██  ██
                            ██ ████ ██ ██    ██ ██ ██  ██ █████   ███████ ██    ██ ██ ███████   ███   ██    ██ ██ ██  ██   ████
                            ██  ██  ██ ██    ██ ██  ██ ██ ██  ██  ██   ██  ██  ██  ██      ██  ███    ██    ██ ██  ██ ██    ██
                            ██      ██  ██████  ██   ████ ██   ██ ██   ██   ████   ██ ███████ ███████  ██████  ██   ████    ██


                            ██    ██  █████  ██       █████  ███████ ███████ ████████  ██████
                            ██    ██ ██   ██ ██      ██   ██ ██         ███     ██    ██    ██
                            ██    ██ ███████ ██      ███████ ███████   ███      ██    ██    ██
                             ██  ██  ██   ██ ██      ██   ██      ██  ███       ██    ██    ██
                              ████   ██   ██ ███████ ██   ██ ███████ ███████    ██     ██████
                            */}

                            {
                                this.state.entitas.employee &&
                                <Row>
                                    <Col className="col-12 p-2 pb-3 pb-sm-2">
                                        <EmploymentRelationshipSelection
                                            value={this.state.entitas?.employmentRelationship || null}
                                            employeeID={this.state.entitas?.employee?.id!=null?this.state.entitas?.employee?.id:null}
                                            key={"employment-relationship-selection"+(Math.floor(Math.random() * (999 - 0 + 1)) + 0)}
                                            onChangeHandler={
                                                (event,newEmploymentRelationship)=>{
                                                    this.setState(
                                                        prevstate => ({
                                                            entitas: { 
                                                                ...prevstate.entitas, 
                                                                employmentRelationship: newEmploymentRelationship,
                                                            },
                                                            saved: false
                                                        })
                                                    );
                                                }
                                            }
                                        />
                                    </Col>
                                </Row> 
                            }

                         


                            {/*
                            ████████ ██ ███    ███ ███████ ███████  ██████  ███    ██ ███████
                               ██    ██ ████  ████ ██         ███  ██    ██ ████   ██ ██
                               ██    ██ ██ ████ ██ █████     ███   ██    ██ ██ ██  ██ █████
                               ██    ██ ██  ██  ██ ██       ███    ██    ██ ██  ██ ██ ██
                               ██    ██ ██      ██ ███████ ███████  ██████  ██   ████ ███████
                            */}

                            <Row>
                                <Col className="col-12 p-2 pb-3 pb-sm-2">          
                                    Time zone:
                                    <TimezoneSelect 
                                        style={{width:"100%"}}

                                        /*Mindket sor nagyon fontos a zIndex-et illetoleg!!!*/
                                        menuPortalTarget={document.body} 
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}


                                        placeholder ="Please select the time zone..."
                                        value={this.state.entitas?.timeZone || ""}
                                        onChange={timezone  => {
                                            this.setState(
                                                prevstate => ({
                                                    entitas: { ...prevstate.entitas, timeZone: timezone.value },
                                                    saved: false
                                                })
                                            );
                                        }}
                                    />
                                </Col>
                            </Row> 

                            {/*
                             ██████ ██   ██ ███████  ██████ ██   ██       ██ ███    ██
                            ██      ██   ██ ██      ██      ██  ██        ██ ████   ██
                            ██      ███████ █████   ██      █████   █████ ██ ██ ██  ██
                            ██      ██   ██ ██      ██      ██  ██        ██ ██  ██ ██
                             ██████ ██   ██ ███████  ██████ ██   ██       ██ ██   ████
                            */}

                            <Row>
                                <Col className="col-12 p-2 pb-3 pb-sm-2">
                                    {createDateTimePicker({
                                        prop: "checkIn",
                                        label: "check-in"
                                    })}
                                </Col>
                            </Row> 

                            <Row>
                                <Col className="col-12 p-2 pb-3 pb-sm-2">
                                    {createDateTimePicker({
                                        prop: "checkOut",
                                        label: "check-out"
                                    })}
                                </Col>
                            </Row>                             




                            <Row>
                                <Col className="d-flex align-items-center col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                    Employee rates:
                                </Col>
                                <Col className="col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                    {createTextFieldWithUnit({
                                        p:"hourlyRateForEmployee",
                                        l:"hourly rate",
                                        unit:"$",
                                        unitPosition:"start",
                                    })}                                
                                </Col>   
                                <Col className="col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                    {createTextFieldWithUnit({
                                        p:"hourlyRateForEmployeeOvertime",
                                        l:"overtime",
                                        unit:"$",
                                        unitPosition:"start",
                                    })}
                                </Col>    
                                { 
                                    this.state.entitas.hourlyRateForEmployeeOvertime!=null &&
                                    this.state.entitas.hourlyRateForEmployee!=null &&
                                    <Col className="d-flex align-items-center col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                        {(this.state.entitas.hourlyRateForEmployeeOvertime/this.state.entitas.hourlyRateForEmployee*100).toFixed(2)}%
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col className="d-flex align-items-center col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                    Client rates:
                                </Col>
                                <Col className="col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                    {createTextFieldWithUnit({
                                        p:"hourlyRateBillToClient",
                                        l:"hourly rate",
                                        unit:"$",
                                        unitPosition:"start",
                                    })}                                
                                </Col>   
                                <Col className="col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                    {createTextFieldWithUnit({
                                        p:"hourlyRateBillToClientOvertime",
                                        l:"overtime",
                                        unit:"$",
                                        unitPosition:"start",
                                    })}
                                </Col> 
                                { 
                                    this.state.entitas.hourlyRateBillToClientOvertime!=null &&
                                    this.state.entitas.hourlyRateBillToClient!=null &&
                                    <Col className="d-flex align-items-center col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                        {(this.state.entitas.hourlyRateBillToClientOvertime/this.state.entitas.hourlyRateBillToClient*100).toFixed(2)}%
                                    </Col>
                                }                                   
                            </Row>

                            <Row>
                                <Col className="d-flex align-items-center col-auto  p-2 pb-3 pb-sm-2">
                                    Deductible lunch break duration:
                                </Col>
                                <Col className="col-12 col-sm-3 col-lg-2 p-2 pb-3 pb-sm-2">
                                    {createTextFieldWithUnit({
                                        p:"deductibleLunchBreakDuration",
                                        l:"duration",
                                        unit:"minutes",
                                    })}                                
                                </Col>    
                            </Row>                            


                            {/*
                             ██████  ██████  ███    ███ ███    ███ ███████ ███    ██ ████████
                            ██      ██    ██ ████  ████ ████  ████ ██      ████   ██    ██
                            ██      ██    ██ ██ ████ ██ ██ ████ ██ █████   ██ ██  ██    ██
                            ██      ██    ██ ██  ██  ██ ██  ██  ██ ██      ██  ██ ██    ██
                             ██████  ██████  ██      ██ ██      ██ ███████ ██   ████    ██
                            */}
                            <Row>
                                <Col className="col-12 p-2">
                                    {createTextArea({p:"comment",l:"comment"})}
                                </Col>
                            </Row>   


                            <Row>

                                {/*
                                ██████  ███████ ███████ ███████ ██████  ███████ ███    ██  ██████ ███████
                                ██   ██ ██      ██      ██      ██   ██ ██      ████   ██ ██      ██
                                ██████  █████   █████   █████   ██████  █████   ██ ██  ██ ██      █████
                                ██   ██ ██      ██      ██      ██   ██ ██      ██  ██ ██ ██      ██
                                ██   ██ ███████ ██      ███████ ██   ██ ███████ ██   ████  ██████ ███████


                                ██████  ███████ ██████  ███████  ██████  ███    ██
                                ██   ██ ██      ██   ██ ██      ██    ██ ████   ██
                                ██████  █████   ██████  ███████ ██    ██ ██ ██  ██
                                ██      ██      ██   ██      ██ ██    ██ ██  ██ ██
                                ██      ███████ ██   ██ ███████  ██████  ██   ████


                                ██    ██  █████  ██       █████  ███████ ███████ ████████  ██████
                                ██    ██ ██   ██ ██      ██   ██ ██         ███     ██    ██    ██
                                ██    ██ ███████ ██      ███████ ███████   ███      ██    ██    ██
                                 ██  ██  ██   ██ ██      ██   ██      ██  ███       ██    ██    ██
                                  ████   ██   ██ ███████ ██   ██ ███████ ███████    ██     ██████                         
                                */}

                                <Col className="col-12 col-sm-9 p-2 pb-3 pb-sm-2">
                                    <EmployeeSelection
                                        value={this.state.entitas?.referencePerson || null}
                                        label="Reference person"
                                        onChangeHandler={
                                            (event,newReferencePerson)=>{
                                                this.setState(
                                                    prevstate => ({
                                                        entitas: { 
                                                            ...prevstate.entitas, 
                                                            referencePerson: newReferencePerson,
                                                        },
                                                        saved: false
                                                    })
                                                );
                                            }
                                        }
                                    />
                                </Col>

                                {/*
                                ██████  ███████ ███████ ███████ ██████  ███████ ███    ██  ██████ ███████
                                ██   ██ ██      ██      ██      ██   ██ ██      ████   ██ ██      ██
                                ██████  █████   █████   █████   ██████  █████   ██ ██  ██ ██      █████
                                ██   ██ ██      ██      ██      ██   ██ ██      ██  ██ ██ ██      ██
                                ██   ██ ███████ ██      ███████ ██   ██ ███████ ██   ████  ██████ ███████


                                ██████  ███████ ██████  ███████  ██████  ███    ██
                                ██   ██ ██      ██   ██ ██      ██    ██ ████   ██
                                ██████  █████   ██████  ███████ ██    ██ ██ ██  ██
                                ██      ██      ██   ██      ██ ██    ██ ██  ██ ██
                                ██      ███████ ██   ██ ███████  ██████  ██   ████


                                ██████  ██████  ███    ███ ███    ███ ██ ███████ ███████ ██  ██████  ███    ██
                                ██      ██    ██ ████  ████ ████  ████ ██ ██      ██      ██ ██    ██ ████   ██
                                ██      ██    ██ ██ ████ ██ ██ ████ ██ ██ ███████ ███████ ██ ██    ██ ██ ██  ██
                                ██      ██    ██ ██  ██  ██ ██  ██  ██ ██      ██      ██ ██ ██    ██ ██  ██ ██
                                ██████  ██████  ██      ██ ██      ██ ██ ███████ ███████ ██  ██████  ██   ████
                                */}

                                <Col className="col-12 col-sm-3 p-2 pb-3 pb-sm-2">
                                    {createTextFieldWithUnit({
                                        p:"referencePersonCommission",
                                        l:"reference person commission",
                                        unit:"$",
                                        unitPosition:"start"
                                    })}
                                </Col>   
                            </Row> 

                            <Row>
                                <Col className="col-12 col-sm-9 p-2 pb-3 pb-sm-2">

                                    <FormGroup>
                                        {createSwitch({
                                            prop:"approvedByClient",
                                            label:"Approved by client",
                                            onChangeHandler:e => {
                                                const v= e.target.checked
                                                this.setState(
                                                    prevstate => ({
                                                        entitas: { 
                                                            ...prevstate.entitas, 
                                                            approvedByClient: e.target.checked,
                                                            ...(v && {problemReportedByClient: false}),
                                                            ...(v && {problemReportedByEmployee: false}),
                                                        },
                                                        saved: false
                                                    })
                                                );
                                            }
                                        })}
                                        {createSwitch({
                                            prop:"problemReportedByClient",
                                            label:"Problem reported by client",
                                            color:"warning",
                                            onChangeHandler:e => {
                                                const v= e.target.checked
                                                this.setState(
                                                    prevstate => ({
                                                        entitas: { 
                                                            ...prevstate.entitas, 
                                                            problemReportedByClient: v,
                                                            ...(v && {approvedByClient: false})
                                                        },
                                                        saved: false
                                                    })
                                                );
                                            }                                            
                                        })}
                                        {createSwitch({
                                            prop:"problemReportedByEmployee",
                                            label:"Problem reported by employee",
                                            color:"warning",
                                            onChangeHandler:e => {
                                                const v= e.target.checked
                                                this.setState(
                                                    prevstate => ({
                                                        entitas: { 
                                                            ...prevstate.entitas, 
                                                            problemReportedByEmployee: v,
                                                            ...(v && {approvedByClient: false})
                                                        },
                                                        saved: false
                                                    })
                                                );
                                            }                                            
                                        })}
                                    </FormGroup>



                                    
                                </Col>
                            </Row> 


                    </form>


                    <Row className="justify-content-between">
                        {
                            this.additionalButton &&
                            <Col>
                                    {React.cloneElement(this.additionalButton,{ className: "m-2 ms-0 mt-3" })}
                            </Col>
                        }
                        <Col className="col-auto">
                            <Button
                                onClick={this.saveOrUpdate}
                                className="m-2 ms-0 mt-3"
                                variant={this.state.saved?"primary":"danger"}
                            >
                                {this.state.entitas?.id ? "Save item" : "Create new item"}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}