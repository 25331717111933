import TextField from '@mui/material/TextField';

export default function TextField1({
    maxLength = 255,
    className = "w-100",
    disabled,
    style={ backgroundColor: "white" },
    size="small",
    label,
    ...rest
}) {
    return (<TextField
        style={style}
        size={size}

        inputProps={{ maxLength }}
        className={className}

        disabled={disabled}
        label={label}

        {...rest}
    />)
}