import React from 'react';
import { IconProps } from './types';

export const Key: React.FC<IconProps> = ({ size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="1 6 21 12"
      fill="currentColor"
      {...rest}
    >
      <g >
        <path
          fillRule="evenodd"
          d="M9.593 9.5H21v3h-3V17h-2.5v-4.5H9.593S8.5 15 6 15s-4-1.79-4-4 1.5-4 4-4 3.593 2.5 3.593 2.5zM3 11a3 3 0 106 0 3 3 0 00-6 0z"
          clipRule="evenodd"
        ></path>
        <path d="M21 9.5v-1a1 1 0 011 1h-1zm-11.407 0v1a1 1 0 01-.916-.6l.916-.4zM21 12.5h1a1 1 0 01-1 1v-1zm-3 0h-1a1 1 0 011-1v1zm0 4.5h1a1 1 0 01-1 1v-1zm-2.5 0v1a1 1 0 01-1-1h1zm0-4.5v-1a1 1 0 011 1h-1zm-5.907 0l-.916-.4a1 1 0 01.916-.6v1zM6 15v-1 1zm0-1v-1 1zm-3-3H2h1zm18-.5H9.593v-2H21v2zm-1 2v-3h2v3h-2zm-2-1h3v2h-3v-2zM17 17v-4.5h2V17h-2zm-1.5-1H18v2h-2.5v-2zm1-3.5V17h-2v-4.5h2zm-6.907-1H15.5v2H9.593v-2zM6 14c.883 0 1.535-.433 2.004-.934a4.207 4.207 0 00.671-.964l.004-.008v.001l-.001.002a.02.02 0 010 .002l.915.401.916.401v.001l-.001.002-.002.005a6.196 6.196 0 01-1.042 1.525C8.76 15.184 7.617 16 6 16v-2zm-3-3c0 .879.296 1.623.788 2.14C4.27 13.649 5 14 6 14v2c-1.501 0-2.771-.544-3.663-1.483C1.454 13.587 1 12.331 1 11h2zm3-3c-.999 0-1.729.351-2.212.86C3.296 9.377 3 10.121 3 11H1c0-1.33.454-2.586 1.337-3.517C3.23 6.544 4.5 6 6 6v2zm3.593 1.5l-.916.401v.001l.001.001v.002h.001a.539.539 0 00-.03-.059 4.202 4.202 0 00-.646-.912C7.536 8.434 6.884 8 6.001 8V6c1.617 0 2.761.817 3.464 1.566a6.199 6.199 0 01.969 1.373 3.968 3.968 0 01.068.14c.002.005.003.01.005.012l.002.005v.002h.001l-.916.402zM6 15a4 4 0 01-4-4h2a2 2 0 002 2v2zm4-4a4 4 0 01-4 4v-2a2 2 0 002-2h2zM6 7a4 4 0 014 4H8a2 2 0 00-2-2V7zm-4 4a4 4 0 014-4v2a2 2 0 00-2 2H2z"></path>
      </g>
    </svg>
  );
};