import React from 'react'
import ReactDOM from 'react-dom/client'

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Minusz from "../assets/minus-cirlce-svgrepo-com.svg"
import Jobbra from "../assets/arrow-right-circle.svg"
import CreatableSelect from 'react-select/creatable';
import { Typeahead } from 'react-bootstrap-typeahead';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import axios from 'axios';


export default class ClientPositionController extends React.Component {
    constructor(props) {
        super(props);
        this.index=props.index
        this.position=props.position
        this.onMinuszFigyelo=props.onMinuszFigyelo
        this.onValtozasFigyelo=props.onValtozasFigyelo
        this.state = {
            position: this.position,
            nameControllerValue:this.position.name?[this.position.name]:[],
            nameControllerOptions:[],
        };
    }



    createInput=(prop)=> {
        return <Col className="align-self-center p-0 pe-2 col-6 col-sm-4 col-lg-4 mb-2 mb-lg-0">
            <Form.Control
                style={{ display: "inline-block",width:"100%" }}
                type="text"
                placeholder={prop}
                onChange={(e)=> {
                    this.setState(
                        prevstate=>({position:{...prevstate.position,[prop]:e.target.value}}),
                        ()=>{
                            if(this.onValtozasFigyelo)
                                this.onValtozasFigyelo(
                                    {index:this.index,
                                    tulajdonsag:prop,
                                    ujertek:e.target.value,
                                    entitas:this.state.position})
                        }
                    );
                }}
                value={this.state.position[prop]||""}
                size="sm" />
            </Col>
    }

    loadNames=async ()=>{
        const resp = await axios(import.meta.env.VITE_SPRING_CONTEXT_PATH+"/api/client-position/names")
        const names= resp.data
        this.setState({ 
            nameControllerOptions: names
        })
    }

    componentDidMount=()=> {
        this.loadNames()
    }

    createInputSelect2=(prop)=> {
        return <Col className="d-inline-block mb-2 mb-md-0 col-12 col-md-4 p-0 pe-2">
            <Typeahead
                id="sajt"
                options={this.state.nameControllerOptions}
                onChange={e=>{this.setState({nameControllerValue:e},()=>{
                    if(e && e.length>0 && this.onValtozasFigyelo)
                        this.onValtozasFigyelo(
                            {index:this.index,
                            tulajdonsag:prop,
                            ujertek:e[0],
                            entitas:this.state.position})
                })}}
                onInputChange={(e)=>{
                    if(this.onValtozasFigyelo)
                        this.onValtozasFigyelo(
                            {index:this.index,
                            tulajdonsag:prop,
                            ujertek:e,
                            entitas:this.state.position})
                }}
                multiple={false}
                selected={this.state.nameControllerValue}
                size="sm"
                placeholder="name"
            />
            </Col>
    }   



    createNumberInput=({prop,label})=> {
        // TODO: beallitsunk-e max numbert?
        return <Col className="align-self-center p-0 pe-2 col-4 col-sm-4 col-md-2 col-lg-2 mb-2 mb-md-0">
            <Form.Control
                style={{ display: "inline-block",width:"100%" }}
                type="number"
                placeholder={label}
                title={label}
                min="0" 
                max="9999"
                onChange={(e)=> {

                    this.setState(
                        prevstate=>({position:{...prevstate.position,[prop]:e.target.value}}),
                        ()=>{
                            if(this.onValtozasFigyelo)
                                this.onValtozasFigyelo(
                                    {index:this.index,
                                    tulajdonsag:prop,
                                    ujertek:e.target.value,
                                    entitas:this.state.position})
                        }
                    );
                }}
                value={this.state.position[prop]||""}
                size="sm" />
            </Col>
    }   

    render() {

        return (
            <div className="p-1 mb-2" style={{width:"100%",border:"solid 1px lightgray",borderRadius:"5px",background:"rgba(0,0,0,0.05"}}>

                <Row className="m-0" style={{width:"100%"}}>
                    {this.createInputSelect2("name")}
                    {this.createNumberInput({prop:"billRate",label:"bill rate"})}
                    <Col className="col-auto align-self-center  p-0 pe-2 mb-2 mb-md-0">
                        <a onClick={
                            ()=>
                                this.setState(
                                    prev=>{return {
                                        position:{
                                            ...prev.position,
                                            overtime:prev.position.billRate*1.5}}},
                                    ()=>{
                                        if(this.onValtozasFigyelo)
                                            this.onValtozasFigyelo(
                                                {index:this.index,
                                                tulajdonsag:"overtime",
                                                ujertek:this.state.position.overtime,
                                                entitas:this.state.position})
                                    })
                            }>
                            <img src={Jobbra} style={{width:"25px"}}/>
                        </a>
                    </Col >
                    {this.createNumberInput({prop:"overtime",label:"overtime"})}

                    <Col className="col-auto align-self-center p-0 mb-2 mb-md-0">
                        <a onClick={e=>{if(false && !confirm("Confirm?")) return;this.onMinuszFigyelo && this.onMinuszFigyelo(this.index)}}>
                            <img src={Minusz} style={{width:"25px"}}/>
                        </a>
                    </Col> 
                </Row>               
                
            </div>
            

        );
    }
}