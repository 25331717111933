import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';


/**
 * Description
 * @param {any} {//amaxLengthnemmukodikittunit
 * @param {any} type="number"
 * @param {any} unitPosition="end"
 * @param {any} className="w-100"
 * @param {any} disabled
 * @param {any} numericInputArrows=false
 * @param {any} //MaterialUI:Removeup/downarrowdialsfromTextViewlabelAnimation=false
 * @param {any} //Acimkenemugrikbestyle={backgroundColor:"white"}
 * @param {any} size="small"
 * @param {any} label
 * @param {any} ...rest}
 * @returns {any}
 */
function TextFieldWithUnit1({
    // a maxLength nem mukodik itt
    unit,
    type="number",
    unitPosition = "end",
    className = "w-100",
    disabled,
    numericInputArrows=false, // Material UI: Remove up/down arrow dials from TextView
    labelAnimation=false, // A cimke nem ugrik be
    style={ backgroundColor: "white" },
    size="small",
    label,
    ...rest
}) {
    const unitPositionKeyPair=unitPosition=="end"?
        {endAdornment:   <InputAdornment position="end">{unit}</InputAdornment>}:
        {startAdornment: <InputAdornment position="start">{unit}</InputAdornment>}
    let classnames=[className]
    if(!numericInputArrows)
        classnames.push("no-numeric-input-arrows")
    const labelAnimationKeyPair=labelAnimation?
        null:
        {InputLabelProps:{shrink: true}}

    return (
        <TextField
            style={style}
            size={size}
            type={type}

            variant="outlined"

            InputProps={{ 
                ...unitPositionKeyPair
            }}

            {...labelAnimationKeyPair}
            
            className={classnames.join(" ")}

            disabled={disabled}
            label={label}

            {...rest}
        />
    )
}

export default TextFieldWithUnit1