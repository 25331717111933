import React from 'react';
import { IconProps } from './types';

export const Client: React.FC<IconProps> = ({ size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="2 3 20 18"
      fill="currentColor"
      {...rest}
    >
      <g>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M17 19h2v-8h-6v8h2v-6h2v6zM3 19V4a1 1 0 011-1h14a1 1 0 011 1v5h2v10h1v2H2v-2h1zm4-8v2h2v-2H7zm0 4v2h2v-2H7zm0-8v2h2V7H7z"></path>
      </g>
    </svg>
  );
};